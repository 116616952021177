<header id="header-container">
  <section>
    <div class="container-fluid mh-section-content d-flex align-items-center">
      <i class="bi bi-list d-block d-md-none display-5 pe-4" (click)="isMenuCollapsed = !isMenuCollapsed" [attr.aria-expanded]="!isMenuCollapsed"></i>
      <div class="header-item d-flex align-items-center jusitify-content-center">
        <img id="header-logo-horizon" [src]="headerContents.logoPath" [alt]="headerContents.altText" class="img-fluid d-none d-md-block"/>
        <img id="header-logo-stacked" [src]="headerContents.mediaHeaderLogoPath" [alt]="headerContents.altText" class="img-fluid height-42 d-block d-md-none"/>
        <img id="header-logo-separator" src="/assets/images/vertical_line.svg" class="d-block d-md-block px-3"/>
        <h1 class="text-white title d-block d-md-none font-size-22" id="app-header-ic-mh-logo">{{headerContents.appTitle}}</h1>
      </div>
      <div class="header-item flex-grow-1 title d-flex align-items-center">
        <h1 class="text-white d-none d-md-block">{{headerContents.appTitle}}</h1>
      </div>
      <app-user-settings *ngIf="(hideUserSettings | async) === false"></app-user-settings>
      <!--Show spinner when userSettings is hidden-->
      <div *ngIf="(hideUserSettings | async)">        
        <div class="flex-grow-1 user-settings-container border-left">
          <div class="d-flex align-items-center justify-content-center flex-grow-1 ps-4 pe-1">
              <span class="rounded-circle d-flex align-items-center justify-content-center">
                  <div class="spinner-border small d-inline-block" role="status">
                      <span class="visually-hidden">Loading...</span>
                  </div>
              </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div *ngIf="(hideUserSettings | async) === false" [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse d-md-block">
    <app-main-navigation></app-main-navigation>
  </div>
</header>