<div class="row scroll-to-section-container py-3 font-size-14" id="ca-scroll-to-section" *ngIf="scrollItem?.length">
  <div class="col">
    <div class="scroll-header fw-bold py-1" [ngClass]="{'pe-3': (currentPosition >= 0) }" id="ca-scroll-to-section-hdr">Scroll to section: </div>
    <!-- Keyboard Left Arrow Icon -->
    <div class="icon-container">
      <a href="javascript:void(0)" aria-label='Keyboard Arrow Left' (click)="moveItem('left')" *ngIf="currentPosition < 0">
        <i class="mh-material-icons icons align-middle d-inline-block font-size-24">
          <span class="visually-hidden">Keyboard Arrow Left</span>
          keyboard_arrow_left
        </i>
      </a>
    </div>
    <div id="linkParent" class="link-container">
      <div id="linkWrapper" class="link-wrapper py-1">
        <span [attr.id]="'pos'+i" *ngFor="let element of scrollItem; index as i">
          <a href="javascript:void(0)" aria-label="Scroll to a section" class="scroll-label pe-2 text-decoration-none" (click)="scrollTo(element.id, element.name, element.type)" [ngClass]="element.disableScrollSection ? 'is-disabled':''">{{element.name}}</a>
          <!-- Spinner -->
          <div *ngIf="element.type && element.showLoader" class="spinner-border spinner-border-sm d-inline-flex align-middle" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </span>
      </div>
    </div>
    <!-- Keyboard Right Arrow Icon -->
    <div class="icon-container">
      <a href="javascript:void(0)" aria-label='Keyboard Arrow Right' *ngIf="nextBtn" (click)="moveItem('right')">
        <i class="mh-material-icons icons align-middle d-inline-block font-size-24">
          <span class="visually-hidden">Keyboard Arrow Right</span>
          keyboard_arrow_right
        </i>
      </a>
    </div>
    <div [ngClass]='{"disabled": currentStatus || downloadPdf?.isPDFBuilding, "cursor-pointer": !currentStatus}'
      class="d-flex justify-content-end disabled download-btn text-right mt-1" *ngIf="downloadPdf?.showDownload">
      <div *ngIf="downloadPdf?.isPDFBuilding" class="spinner-border spinner-border-sm d-inline-flex download-spinner" role="status"></div>
      <a class="download" aria-label='download option'>
          <i class="mh-material-icons">
            <span class="visually-hidden">Download Export</span>
            file_download
          </i>
        <a class="fw-bold link-primary" (click)="triggerDownloadCallback({})">Download</a>
      </a>
    </div>
  </div>
</div>
