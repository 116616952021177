export enum RefCacheEnum {
  SERVICE_CATEGORY = 'campaign_service_category',
  FACILITY = 'campaign_facility',
  SUB_SERVICE_CATEGORY = 'campaign_sub_service_category',
  VISIT_TYPES = 'campaign_visit_types',
  STATUS = 'campaign_status',
  CAMPAIGN_TYPE = 'campaign_type',
  COST_TYPE = 'cost_type',
  CHANNELS = 'channels',
  TEMPLATE_SERVICE_CATEGORY = 'audience_service_category',
  TEMPLATE_SUB_SERVICE_CATEGORY = 'audience_sub_service_category',
}

export enum AudienceCacheEnum {
  CRITERIA_LIST_ITEMS = 'criteriaListItems',
}

// Here we need to add template settings enum