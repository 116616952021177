import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export const url: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const value: string = control.value;
  return /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(value) ? null : { 'pattern': true };
};