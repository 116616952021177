/**
 * This enum is used for referring Primary Navigation URL Path.
 */
export enum MainNavigationEnum {
  APPLICATION_INTEGRATIONS = 'application-integrations',
  AUDIENCE_INSIGHTS = 'audience-insights',
  CAMPAIGN_WORKFLOW = 'campaign-workflow',
  CLIENT_LIST = 'client-list',
  CPI = 'cpi',
  LOGIN = 'login',
  LOOKUP = 'lookup',
  MPI = 'mpi',
  UNAUTHORIZED = 'unauthorized',
  STYLE_GUIDE = 'style-guide',
  PLANNING_RESEARCH = 'planningResearch',
  LEGACY_PREFIX = 'pes',
}
