import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SelectTemplateConfig } from '../models/select-template.model';
import { TCriteria } from 'src/app/audience/models/audience-config.model';

@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss'],
})
export class SelectTemplateComponent implements OnChanges {

  @Input() config: SelectTemplateConfig = {};
  @Input() inputData: TCriteria[] = [];
  @Output() callback: EventEmitter<TCriteria> = new EventEmitter<TCriteria>();
  public isOpen = false;
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.config?.currentValue?.isCombined) {
      this.config.placeholder = `Add other ${this.config.parent} attributes`;
    }
  }

  selectOption(param: TCriteria) {
    this.callback.emit(param);
  }
}
