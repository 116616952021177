// Core Modules
import { ViewportScroller } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-show-more-less',
  templateUrl: './show-more-less.component.html',
  styleUrls: ['./show-more-less.component.scss'],
})
export class ShowMoreLessComponent {
  // Bindings
  @Input() isExpanded: boolean = false;
  @Input() suffixId!: string;
  @Input() scrollElementId!: string;
  @Output() isExpandedChange: EventEmitter<boolean> = new EventEmitter(false);

  /**
   * The constructor method
   * @param viewportScroller Defines a scroll position manager.
   */
  constructor(
    private readonly viewportScroller: ViewportScroller,
  ) { }

  /**
   * scrollToTop method is to set isExpanded to false which reduces the text displayed
   * @param id 
   */
  public scrollToTop(id: string): void {
    if (!(id === 'promoted_facilities-purpose' || id === 'promoted_services-purpose' || id === 'sub_service-purpose')) this.viewportScroller.scrollToPosition([0, 0]);
    this.isExpandedChange.emit(!this.isExpanded);
  }
}
