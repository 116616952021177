import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PaginationConfig, IPageOverview } from '../models/pagination/pagination-config.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})

export class PaginationComponent {

  @Input() config!:PaginationConfig;
  
  /**
   * Input variable for page
   */
  @Input() pageOverview!: IPageOverview;
  
  /**
   * Holds the count of the pagination component
   */
  @Input() currentPageTotalRecords!: number;
  
  /**
   * Holds the count of the pagination component
   */
  @Input() isPageSizeOption?: boolean;
 
  /**
   * Emit button function for pageChangeEvent
  */
  @Output() pageChangeEvent: EventEmitter<any> = new EventEmitter();
  
  /**
   * dropdown selected item event emitter
   * @param event selected item value
   */

  pageSizeChangeHandler() {
    this.pageOverview.num = 1;
    this.pageChangeEvent.emit(this.pageOverview);
  }

  /**
   * 
   * @param value onPageConfig change value
   */
  pageChangeHandler( pageConfig: { action: string }) {
    const action = pageConfig.action;
    const pageSegment = this.pageOverview!;
    switch (action) {
      case 'first':
        pageSegment.num = 1;
        pageSegment.hasNext = true;
        break;
      case 'prev':
        pageSegment.num =  pageSegment.num - 1;
        break;
      case 'next': 
        pageSegment.num =  pageSegment.num + 1;
        break;
      case 'last': 
        pageSegment.num =  pageSegment.maxPage;
        if (pageSegment.num >= pageSegment.maxPage) {
          pageSegment.hasNext = false;
        }
        break;
    }
    this.pageChangeEvent.emit(pageSegment);
  }

  /**
   * 
   * @param goToPage move to page mentioned by user
   */
  callbackRedirectPage(event:any) {
    if ( event.target?.value && (event.target.value <= this.pageOverview.maxPage) && (event.target.value >= 1)) {
      this.pageOverview.num = event.target.value;
    } else {
      this.pageOverview.num = 1;
      event.target.value = 1;
    }
    this.pageChangeEvent.emit(this.pageOverview);
  } 

}