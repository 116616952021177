
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';

import { AuthService } from '../../core/services/auth.service';
import { ConfigService } from '../../core/services/config.service';

/**
 * Auth Login Component
 */
@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements  OnInit, OnDestroy {
  
  /**
   * legacyFederatedDomains to get legacy federated domains
   */
  private legacyFederatedDomains: string[] = [];
  /**
   * auth0Tenant to get auth0 tenant
   */
  private readonly auth0Tenant = this.configService.auth0Tenant;
  /**
   * auth0Domain to get auth0 domain
   */
  private readonly auth0Domain = this.configService.auth0Domain;
  /**
   * subscription to api calls
   */
  private readonly subscription = new Subscription();

  /**
   * customUserEmail to get customer email
   */
  public customUserEmail: string = '';
  /**
   * preAuthLoginFormSubmitted to get boolean :true/false
   */
  public preAuthLoginFormSubmitted = false;
  /**
   * preAuthLoginContainer to set pre auth login container
   */
  public preAuthLoginContainer: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  /**
   * The constructor method
   * @param activatedRoute activated routes
   * @param authService auth service
   * @param configService config service
   * @param formBuilder form builder
   * @param router router
   * @param sessionManager session manager
   * @param accessControlService access control service for permissions
   */
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly cookieService: CookieService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  /**
   * The ngOnInit method
   */
  ngOnInit(): void {
    /**
     * Obtain legacy federated domain from activated route
     */
    this.subscription.add(this.activatedRoute.data.subscribe((legacyDomains: Record<string, string[]>) => {
      if (!legacyDomains?.data) return;
      this.legacyFederatedDomains = legacyDomains.data;
    }));
  }

  /**
   * This method is triggered when user click on next button from Pre-Auth login container
   * Checking for UserEmailDomainName is legacy federated domain or not
   * If UserEmailDomainName = legacy federated domain then initialize Auth0 Lock widget based on legacy federated domain
   * Else initialize Auth0 lock widget based on custom domain
   * @memberof AuthLoginComponent
   */
  onSubmitUserEmail(): void {
    this.cookieService.deleteAll('/');
    sessionStorage.clear();
    if (this.preAuthLoginContainer.invalid) return;
    this.preAuthLoginFormSubmitted = true;
    this.customUserEmail = this.preAuthLoginContainer?.value.email;
    if (this.legacyFederatedDomains.length > 0 && this.customUserEmail.length > 0) {
      this.customUserEmail = this.customUserEmail.toLowerCase();
      const emailDomain = this.customUserEmail.split('@')[1];
      if (emailDomain?.length > 0 && this.legacyFederatedDomains.includes(emailDomain)) {
        // Init Lock widget for legacy federated domain.
        this.authService.initializeLockWidget(
          this.customUserEmail,
          `${this.auth0Tenant}.auth0.com`,
        );
      } else {
        // Init Lock widget for custom domain.
        this.authService.initializeLockWidget(this.customUserEmail, this.auth0Domain);
      }
      const waitForEmailElementRendered = (selector: any, callback: Function) => {
        const poller = setInterval(() => {
          if (this.document?.querySelector(selector)?.value === this.customUserEmail) {
            clearInterval(poller);
            callback(this.customUserEmail);
          }
        }, 100);
      };
      waitForEmailElementRendered('#authLoginContainer .auth0-lock-input', (email: string) => {
        const selectedQueryFromDocument: HTMLElement  = this.document.querySelector('#authLoginContainer .auth0-lock-submit') as HTMLElement;
        const result: string[] = email.split('@');
        if (result.length > 1 && this.legacyFederatedDomains.includes(result[1])) selectedQueryFromDocument.click();
      });
    } else {
      console.log('We haven\'t received proper legacyFederatedDomains, value of legacyFederatedDomains = ', this.legacyFederatedDomains);
      localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  /**
   * The ngOnDestroy method to destroy subscription.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
