<div class="list-view-table-wrapper table-responsive">
  <!-- List View Table -->
  <table class="table table-fixed" [attr.id]="settings?.id" [ngClass]="settings?.cssClass"
    role="table" [attr.aria-label]="settings?.ariaLabel" [attr.aria-describedby]="settings?.ariaLabelDesc"
    [attr.aria-rowcount]="items?.length">
    <thead>
      <tr [ngClass]="settings?.header?.cssClass">
        <th scope="col" [attr.id]="columnId.value?.id" [attr.aria-label]="columnId.value?.ariaLabel"
          *ngFor="let columnId of headers | keyvalue | orderBy: 'value.order'; let i = index" [attr.width]="columnId?.value?.width">
          <!-- sorting filter with dropdown -->
          <ng-container [ngSwitch]="columnId.value?.type">
            <ng-template [ngSwitchCase]="'dropdown'">
              <div [attr.id]="columnId.value?.id ? columnId.value?.id : 'list-view-th-dd-'+i" class="table-list-view-dropdown" ngbDropdown placement="bottom-end" autoClose="outside" container="body" [dropdownClass]="columnId.value.cssClass">
                <span class="d-inline-block align-middle mt-1" [attr.aria-label]="columnId.value?.ariaLabel">{{columnId.value.name}}</span>
                <a href="javascript:void(0)" class="list-view-filter-bar">
                  <i class="bi bi-filter-square px-2 d-inline-block align-middle font-size-16 text-dark" ngbDropdownToggle [attr.id]="columnId.value?.id ? columnId.value?.id+'-icon' : 'list-view-th-dd-icon-'+i"></i>
                </a>
                <div ngbDropdownMenu [attr.aria-labelledby]="columnId.value?.ariaLabel">
                  <ng-template *ngFor="let option of columnId.value?.options"
                  [ngxPermissionsOnly]="option?.permissionOnly"
                  [ngxPermissionsExcept]="option?.permissionExcept">
                    <a ngbDropdownItem href="javascript:void(0)"
                      [attr.id]="'list-view-th-dd-'+option?.value"
                      [ngClass]="selectedFilter === option.value ? 'active': ''"
                      (click)="callback({ type: 'filter', key: option?.key, value: option.value })"
                      [attr.aria-label]="option?.id"
                      [attr.aria-label]="option?.value">
                      {{option?.value}}
                    </a>
                  </ng-template>
                </div>
              </div>
            </ng-template>
            <ng-template [ngSwitchCase]="'ngTemplate'">
              <ng-container [ngTemplateOutlet]="headerTemplate" [ngTemplateOutletContext]="{ headerDetail: columnId }"></ng-container>
            </ng-template>
            <ng-template [ngSwitchDefault]>
              <span class="d-inline-block align-middle">{{columnId.value.name}}</span>
              <a href="javascript:void(0)" [class.active]="columnId.value.name===defaultSort" class="sort-icon position-absolute"
              (click)="callback({ type: 'sorting', element: columnId })" *ngIf="!settings?.header?.hideDropDownToggle">
              <i [attr.id]="columnId?.value?.id+'-down-arrow'" [ngClass]="{'bi bi-caret-up-fill':columnId.value.active,'bi bi-caret-down-fill':!columnId.value.active}" class="px-2 d-inline-block align-middle"></i>
              </a>
            </ng-template>
          </ng-container>
          <!-- sorting filter with dropdown -->
        </th>
      </tr>
    </thead>
    <tbody *ngIf="!displayLoader && items?.length">
      <tr role="row" *ngFor="let row of items; index as i" [attr.id]="settings?.cssClass ? settings?.cssClass+'-'+row.id : row.id">
        <td *ngFor="let columnId of headers | keyvalue | orderBy: 'value.order'">
          <!-- Displaying header when header match with response -->
          <div *ngIf="headers?.columnId?.key !== undefined" class="d-flex">
            <ng-template [ngxPermissionsOnly]="settings?.header[columnId.key]?.icon?.permissionOnly"
              [ngxPermissionsExcept]="settings.header[columnId.key]?.icon?.permissionExcept">
              <i [attr.id]="'icon-'+columnId?.value?.id" class="me-2 d-flex align-items-center" *ngIf="columnId?.value?.icon && row?.iconCss"
              [ngClass]="[columnId.value.icon, row.iconCss]"></i>
            </ng-template>
            <ng-container [ngSwitch]="columnId.value?.colType">
              <ng-template [ngSwitchCase]="'link'">
                <a placement="top" ngbTooltip="{{row[columnId.key]}}"
                [disableTooltip]="row[columnId.key].length<50"  href="javascript:void(0)" class="text-decoration-none" [attr.id]="settings.listViewColumnIdPrefix ? settings.listViewColumnIdPrefix+'-'+row[columnId.key].trim() : row[columnId.key].trim()" [ngClass]="columnId.value?.cssClass" (click)="callback({ type: 'link', row, column: columnId.value })">{{row[columnId.key]}}</a>
              </ng-template>
              <ng-template [ngSwitchCase]="'ngTemplate'">
              <!---------------------------- To handle linked campaign------------------------>
                <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ data: row, header: columnId.value, callback }"></ng-container>
              </ng-template>
              <ng-template [ngSwitchDefault]>
                <span class="text-truncate overflow-hidden" (click)="callback({ type: 'text', value: row })" [attr.id]="settings.listViewColumnIdPrefix+row[columnId.key]">{{row[columnId.key]}}</span>
              </ng-template>
            </ng-container>
          </div>
        </td>
        <td *ngIf="settings?.row?.actions?.length">
          <div class="d-flex align-items-center justify-content-end gap-2"> 
            <!-- User Action Bar: -->
            <ng-container *ngFor="let action of settings?.row?.actions" [ngSwitch]="action?.type">
              <!-- User Action Bar: icon -->
              <ng-template [ngSwitchCase]="'icon'"> 
                <ng-container [ngTemplateOutlet]="iconContent" [ngTemplateOutletContext]="{ row, action, i }"></ng-container>
              </ng-template>
              <!-- User Action Bar : Button -->
              <ng-template [ngSwitchCase]="'button'">
                <ng-container *ngIf="action?.template" [ngTemplateOutlet]="buttonContent" [ngTemplateOutletContext]="{ row, action, i }"></ng-container>
                <ng-container *ngIf="action.id !== 'preview'" >
                  <button *ngIf="!action?.template" type="button" [attr.id]="action?.id+'-'+i+'-btn'" [attr.disabled]="row?.button?.disabled?.[action?.value] ? '' : null"   class="btn me-2" [attr.aria-label]="action?.value" [ngClass]="[action?.cssClass]" (click)="callback({ type: action?.type, value: action?.value, data: row })">
                    {{action?.value}}
                  </button>
                </ng-container>
                <ng-container *ngIf="action.id === 'preview'" >
                    <button type="button" 
                      [ngClass]="[action.cssClass]" 
                      class="btn me-2 form-controls"
                      [ngbPopover]="previewPopoverContent" 
                      [autoClose]="'outside'"
                      popoverClass="preview-popover"
                      triggers="manual"
                      #p="ngbPopover"
                      (click)="p.open(); callback({ type: action?.type, value: action?.value, data: row });">
                      {{action.value}}
                    </button>
                </ng-container>

              </ng-template>
              <!-- User Action Bar : Dropdown -->
              <ng-template [ngSwitchCase]="'dropdown'" >
                <div class="table-list-view-dropdown" ngbDropdown #collapseDropdown="ngbDropdown" placement="bottom-end" autoClose="outside" container="body" [ngClass]="row?.dropdown?.cssClass" *ngxPermissionsOnly="action?.permissionOnly"
                >
                  <a href="javascript:void(0)" class="list-view-action-bar" [attr.id]="action?.id+'-'+i">
                    <i class="bi bi-three-dots-vertical link-primary" tabindex="0" ngbDropdownToggle></i>
                  </a>
                  <div ngbDropdownMenu [attr.aria-labelledby]="action?.ariaLabel" class="dropdown-menu">
                    <!-- drop down option iteration start -->
                    <ng-template *ngFor="let option of action?.options"
                    [ngxPermissionsOnly]="option?.permissionOnly"
                    [ngxPermissionsExcept]="option?.permissionExcept">
                      <ng-container [ngSwitch]="option.type">
                        <!-- If drop down option is type of ngTemplate-->
                        <ng-template [ngSwitchCase]="'ngTemplate'">
                          <ng-container [ngTemplateOutlet]="popoverContent" [ngTemplateOutletContext]="{ row, option, i, collapseDropdown}"></ng-container>
                        </ng-template>
                        <!-- By default, drop down option set to type of link -->
                        <ng-template [ngSwitchDefault]>
                          <a ngbDropdownItem href="javascript:void(0)" [attr.id]="option?.id+'-'+i" *ngIf="row?.dropdown?.visibility?.[option?.value] !== false"  [disabled]="row?.dropdown?.disabled?.[option?.value]" [attr.aria-label]="option?.value" (click)="callback({ type: action?.type, value: option?.value, data: row })">
                            {{option?.value}}
                          </a>
                        </ng-template>
                      </ng-container>
                    </ng-template>
                    <!-- drop down option iteration end -->
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Spinner -->
  <div class="d-flex page-min-height" *ngIf="displayLoader || items?.length===0">
    <div class="flex-grow-1 d-flex align-items-center justify-content-center">
      <div *ngIf="displayLoader" class="spinner-border spinner-border-sm" role="status"></div>
      <p id="no-records-found-err-msg" *ngIf="items?.length === 0 && !displayLoader">{{settings.recordsNotFound || 'No Records Found'}}</p>
    </div>
  </div>
</div>
