export enum ChannelKey {
  EMAIL = 'email',
  DIRECT_MAIL = 'direct_mail',
  OUTBOUND_CALL = 'outbound_call',
  MASS_MEDIA = 'mass_media',
  ORGANIC_SOCIAL = 'organic_social',
  SOCIAL = 'social',
  DISPLAY = 'display',
  OUTDOOR = 'outdoor',
  PAID_SEARCH = 'paid_search',
  VIDEO = 'video',
  ONE_TO_ONE = 'one2one',
  SMS_TEXT = 'sms_text',
}

export enum AudienceSyncKeys {
  EMAIL = 'EMAIL',
  DIRECT_MAIL = 'DIRECT_MAIL',
  OUTBOUND_CALL = 'OUTBOUND_CALL',
}

export enum AudienceOperations {
  SYNC_MARKETING_AUTOMATION = 'Sync with Marketing Automation',
  CREATE_MAIL_LIST = 'Create Mail List',
  CREATE_CALL_LIST = 'Create Outbound Call List',
}

export enum ChannelName {
  EMAIL = 'Email',
  DIRECT_MAIL = 'Direct Mail',
  OUTBOUND_CALL = 'Outbound Call',
}

export enum AttachAudienceOptions {
  EXISTING_AUDIENCE = 'Existing Audience',
  EXISTING_AUDIENCE_TOOLTIP_TEXT = 'Browse existing audiences for a starting point for building your own.',
  CREATE_AUDIENCE = 'Create Audience',
  CREATE_AUDIENCE_TOOLTIP_TEXT = 'Already know what you need to do? Start building your audience from scratch.',
  SMART_TEMPLATE = 'Smart Template',
  SMART_TEMPLATE_TOOLTIP_TEXT = 'Have an idea for what you want to do? Browse templates to quickly get an audience to refine.',
}

export enum AudienceOptions {
  AUDIENCE_SETTINGS = 'Audience Settings',
  PAUSE_AUDIENCE_SYNC = 'Pause Audience Sync',
  SYNC_SETTINGS = 'Sync Settings',
  DETACH_FROM_CAMPAIGN = 'Detach from campaign',
}

export enum ContactListJobStatus {
  COMPLETED = 'COMPLETE',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
}

export enum MarketingAutomationEnum {
  Eloqua = 'Eloqua',
  SFMC = 'SFMC',
  SFHC = 'SFHC',
}

export enum CampaignCommunicationActivityEnum {
  SPECIFIED = 'specified',
  ANY_DATE = 'any-date',
}

export enum SyncTrackJobStatus {
  RUNNING = 'RUNNING',
  FAIL = 'FAILED',
  COMPLETE = 'COMPLETED',
  QUEUE = 'QUEUED',
  PROCESSING = 'PROCESSING',
  MIGRATED = 'MIGRATED',
  DEFAULT = 'NOT_SYNCED',
}

export enum SyncStages {
  DELTA_GENERATION = 'DELTA_GENERATION',
  CONTACT_LIST_GENERATION = 'CONTACT_LIST_GENERATION',
  SYNC_TO_ELOQUA = 'SYNC_TO_ELOQUA',
}

export enum ManagedTypeEnum {
  GENERAL = 'general',
  EDGE = 'edge',
}

export enum ManagedTypeResponseEnum {
  GENERAL = 'General',
  EDGE = 'Edge',
}

export enum ManagedFeatureTypeEnum {
  CAMPAIGN_OVERVIEW_LABEL = 'Campaign Workflow >Overview tab',
  CAMPAIGN_OVERVIEW_VALUE = 'campaignOverview',
  CAMPAIGN_REPORTING_LABEL = 'Campaign Workflow >Campaign Reporting',
  CAMPAIGN_REPORTING_VALUE = 'campaignReporting',
  CAMPAIGN_SETTING_LABEL = 'Campaign Workflow > Campaign Settings',
  CAMPAIGN_SETTING_VALUE = 'campaignSetting',
  AUDIENCE_VIEW_AND_DUPLICATE_LABEL = 'Audience Insights > Duplicate and View Audience(s)',
  AUDIENCE_VIEW_AND_DUPLICATE_VALUE = 'audienceViewAndDuplicate',
}
export enum ReportingParamKeys {
  ID = 'id',
  STANDARD_SERVICE_LINES = 'standardServiceLines',
  STANDARD_SUB_SERVICE_LINES = 'standardSubServiceLines',
  CUSTOM_SERVICE_LINES = 'customServiceLines',
  CUSTOM_SUB_SERVICE_LINES = 'customSubServiceLines',
  FACILITIES = 'facilities',
  TRACK_RESPONSES_MONTHS = 'trackResponsesMonths',
  COMMUNICATION_START_DATE = 'communicationStartDate',
  COMMUNICATION_END_DATE = 'communicationEndDate',
  VISIT_TYPES = 'visitTypes',
  DIAGNOSIS = 'diagnosis',
  PROCEDURE = 'procedure',
  MSDRG = 'msdrg',
  COST_SCHEDULES = 'costSchedules',
}
