<div class="btn-group w-100 combined-group bg-white rounded-bottom" (click)="$event.stopPropagation();" ngbDropdown
    [open]="isOpen" [ngClass]="{'active': isOpen || config.isDragTrigger}" (openChange)="isOpen = !isOpen" placement="bottom">
    <div class="input-group" ngbDropdownToggle [ngClass]="{'active': isOpen || config.isDragTrigger }">
        <input type="text" id="ta-ea-cb-lb-lg-fg-filter-name-input" class="form-control font-size-14 border-0"
            autocomplete="off" [placeholder]="config.placeholder" [(ngModel)]="config.filterName" (blur)="config.filterName= ''"
            [ngClass]="{'active': isOpen || config.isDragTrigger}" [class.isOpen]="isOpen">
        <div class="input-group-prepend" [ngClass]="{'active': isOpen || config.isDragTrigger}">
            <button id="ta-ea-cb-lb-lg-fg-btn-arrowddcircle" class="btn btn-default bg-white px-1 border-0"
                [ngClass]="{'active': isOpen || config.isDragTrigger }">
                <i class="mh-material-icons font-size-26">arrow_drop_down_circle</i>
            </button>
        </div>
    </div>
    <ul class="dropdown-menu w-100 font-size-14" ngbDropdownMenu role="menu" aria-labelledby="btn-append-to-body">
        <li ngbDropdownItem class="dropdown-item cursor-pointer" id="ta-ea-cb-lb-lg-fg-add-criteria" role="menuitem"
            *ngFor="let result of inputData | filterBy : ['name']: config.filterName | orderBy : 'name'"
            (mousedown)="config.selectedTemplate = result.name; selectOption(result);">
            {{result.name}}
        </li>
        <li class="dropdown-item cursor-disabled" id="ta-ea-cb-lb-lg-fg-err-msg" role="menuitem"
            *ngIf="(inputData | filterBy: ['name'] : config.filterName).length === 0">
            No record found</li>
    </ul>
</div>