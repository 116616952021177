<div class="flex-cols w-100 login-container bg-white">
    <div class="wmdi-login-background col-flex d-flex justify-content-center align-items-center">
        <img alt="WebMD Ignite Growth Platform Logo" src='assets/images/wmdi-growth-platform-logo.svg' class="img-fluid">
    </div>
    <div class="auth0-lock-container col-flex justify-content-center align-items-center">
        <div class="row h-100 d-flex justify-content-center align-items-center">
            <div class="col-12">
                <div class="card border-0 text-center mb-1">
                    <img class="wmdi-logo-container rounded img-fluid p-2" src="assets/images/webmd-ignite-icon.svg"
                        alt="WebMD Ignite Growth Platform Logo">
                    <h2 class="mb-0 wmdi-logo fw-bold">Log in</h2>
                </div>
                <!-- Pre Auth Login Container -->
                <form [formGroup]="preAuthLoginContainer" id="preAuthLoginContainer" (ngSubmit)="onSubmitUserEmail()"
                    *ngIf="!preAuthLoginFormSubmitted">
                    <div class="mb-3">
                        <label for="userName" class="form-label">Email address</label>
                        <input type="email" formControlName="email" class="form-control" id="userName"
                            aria-describedby="userName" placeholder="yours@example.com">
                    </div>
                    <button type="submit" *ngIf="preAuthLoginContainer.valid && !preAuthLoginContainer.controls.errors"
                        class="btn btn-primary" id="next-btn">Next</button>
                </form>
                <!-- Auth0 Lock Container -->
                <div id="authLoginContainer" [hidden]="!(preAuthLoginContainer.valid && preAuthLoginFormSubmitted)">
                    <p class="text-center"> <strong class="p-2">&#10004;</strong>Thanks for logging in.</p>
                </div>
            </div>
        </div>
    </div>
</div>