/**
 * Consumer Profiler Mapper
 * @returns consumer profiler mapper
 */
export const consumerMapper = () => {
  return {
    'consumer-profile-viewer': ['consumer-profile/search:read', 'consumer-profile/demographics:read', 'crm:read'],
    'demographics-read': ['consumer-profile/demographics:read'],
    'activities-read': ['consumer-profile/activity:read'],
  };
};
