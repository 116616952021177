<nav id="app-header-nav-section" class="container-fluid">
  <div class="mh-section-content">
    <ul class="nav primary-navbar nav bg-white flex-column flex-md-row p-0 justify-content-start">
      <li class="nav-item text-center" [ngClass]="menuItem.cssClass ? menuItem.cssClass : 'pt-md-4'"
        *ngFor="let menuItem of menu">
        <ng-container *ngxPermissionsOnly="menuItem.permissionScope">
          <a *ngIf="!menuItem.icon" [routerLinkActive]="'active'" [id]="menuItem.id" [attr.aria-label]="menuItem.label"
            class="nav-link" [routerLink]="menuItem.path" (click)='navigateToRoute(menuItem.path)'>{{ menuItem.label}}</a>
          <a *ngIf="menuItem.icon" [routerLinkActive]="'active'" [id]="menuItem.id" [attr.aria-label]="menuItem.label"
            class="nav-link" [routerLink]="menuItem.path" (click)='navigateToRoute(menuItem.path)'>
            <img class="img-fluid my-1" [attr.aria-label]="menuItem.label" [src]="menuItem.icon" />
            <span class="d-block fw-normal">{{ menuItem.label }}</span>
          </a>
        </ng-container>
      </li>
    </ul>
  </div>
</nav>