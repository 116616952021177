/**
 * User Settings Mapper
 * @returns user settings mapper
 */
export const userSettingsMapper = () => {
  return {
    'crm-read': ['crm:read'],
    'user-settings-market-automation': ['jobs:read', 'jobs:write', 'jobs:write:sync-contacts'],
    'app-integrations-admin': ['integrations:source-field-mappings:read', 'integrations:source-field-mappings:write', 'salesforce-oauth2/session:write'],
    'eloqua-integrations': ['integrations:source-field-mappings:write', 'integrations:source-field-mappings:read'],
    'user-settings-usage-report': ['app/usage-activity:read'],
    'file-config-read': ['configurations:read'],
    'file-config-write': ['configurations:write'],
    'file-uploads-read': ['uploads:read'],
    'uploads-write': ['uploads:write'],
    'google-auth2-read-all-integrations': ['google-oauth2/authorizations:read'],
    'salesforce-auth2-write-session': ['salesforce-oauth2/session:write'],
    'google-analytics-validate': ['integrations:google-analytics:validate'],
    'client-ma-preference-read': ['integrations:client-ma-preference:read'],
    'support-center-access': ['support-center:access'],
  };
};
  