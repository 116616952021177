import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, Observable, catchError } from 'rxjs';

import { ConfigService } from '../../services/config.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

import { audienceInsightsViewerMapper } from '../audience-insights-viewer-mapper';
import { campaignsMapper } from '../campaigns-mapper';
import { consumerMapper } from '../consumer-profile-mapper';
import { cpiMapper } from '../campaign-performance-insights-mapper';
import { marketPlannerInsightsViewerMapper } from '../market-planner-insights-viewer-mapper';
import { userSettingsMapper } from '../user-settings-mapper';

import { MainNavigationEnum } from '../../../pem-shared/enum/main-navigation-url.enum';
import { AppEndpointsModel } from 'src/app/pem-shared/models/endpoints.config.model';

import { environment } from '../../../../environments/environment';

/**
 * Access Control Service
 */
@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  /**
   * aiMapper to map with audience insights viewer mapper
   */
  private aiMapper: Record<string, any[]>;
  /**
   * appEndpoints to get api endpoints
   */
  private appEndpoints: AppEndpointsModel;
  /**
   * applicationScopes to get mapped application scopes
   */
  private applicationScopes: Record<string, any[]>;
  /**
   * campaignWorkflowMapper to map with campaign workflow viewer mapper
   */
  private campaignWorkflowMapper: Record<string, any[]>;
  /**
   * consumerProfileMapper to map with consumer profiler viewer mapper
   */
  private consumerProfileMapper: Record<string, any[]>;
  /**
   * cpi Mapper to map with CPI viewer mapper
   */
  private cpiMapper: Record<string, any[]>;
  /**
   * mpiMapper to map with MPI viewer mapper
   */
  private mpiMapper: Record<string, any[]>;
  /**
   * userSettingMapper to map with user settings
   */
  private userSettingMapper: Record<string, any[]>;

  /**
   * The constructor method
   * @param configService config service
   * @param errorHandlerService error handler service
   * @param httpClient httpClient for api calls
   * @param permissionsService permission service
   */
  constructor(
    private readonly configService: ConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpClient: HttpClient,
    private readonly permissionsService: NgxPermissionsService) {
    // mappers
    this.aiMapper = audienceInsightsViewerMapper();
    this.campaignWorkflowMapper = campaignsMapper();
    this.consumerProfileMapper = consumerMapper();
    this.cpiMapper = cpiMapper();
    this.mpiMapper = marketPlannerInsightsViewerMapper();
    this.userSettingMapper = userSettingsMapper();

    this.applicationScopes = { ...this.mpiMapper, ...this.aiMapper, ...this.consumerProfileMapper, ...this.campaignWorkflowMapper, ...this.cpiMapper, ...this.userSettingMapper };
    this.appEndpoints = this.configService.getEndpointsByModule('appEndpoints');
  }

  /**
   * This method is used for fetching user scope information.
   * @return {*}
   * @memberof AccessControlService
   */
  public getUserScopes() {
    return this.httpClient.get(`${this.configService.getAPIBaseUrl}${this.appEndpoints.userScopes}`)
      .pipe(
        map((data: any) => data), catchError(this.errorHandlerService.errorHandler),
      );
  }

  /**
   * Load the permission for NgxPermission and return current user permissions.
   * @returns current user permissions.
   */
  definePermissions(): Observable<boolean> {
    return this.getUserScopes().pipe(
      // get the user scope
      map((resp: any) => {
        // Filter the permission from mapper with user scope.
        const currentAppPermissions = Object.keys(this.applicationScopes).filter((key) =>
          this.applicationScopes[key].every((value: string) => resp.scopes.includes(value)),
        );
        if (resp.veratoMigration) {
          currentAppPermissions.push('veratoMigrationInProgress');
        }
        // To Make sure that we have both LKP Feature Enabled & Client is having `hasLkpAccess`
        if (resp.hasLkpAccess && environment.ENABLE_LKP) {
          currentAppPermissions.push('hasLkpAccess');
        }
       
        // load the matched permission keys in the NgxPermissionService.
        this.permissionsService.loadPermissions(currentAppPermissions);
        return true;

      }), catchError(this.errorHandlerService.errorHandler),
    );
  }

  /**
   * Check if current user have permission for default landing.
   * @returns authorized route
   */
  async getLandingPageBasedOnUserScopes(): Promise<string> {

    let userLandingPageBasedOnUserScopes: boolean;

    userLandingPageBasedOnUserScopes = await this.permissionsService.hasPermission(['standard-report-viewer', 'consumer-iq-report-viewer', 'retention-iq-report-viewer']);
    if (userLandingPageBasedOnUserScopes) {
      return `/${MainNavigationEnum.MPI}`;
    }

    userLandingPageBasedOnUserScopes = await this.permissionsService.hasPermission('audience-insights-viewer');
    if (userLandingPageBasedOnUserScopes) {
      return `/${MainNavigationEnum.AUDIENCE_INSIGHTS}`;
    }
    
    userLandingPageBasedOnUserScopes = await this.permissionsService.hasPermission('campaign-viewer');
    if (userLandingPageBasedOnUserScopes) {
      return `/${MainNavigationEnum.CAMPAIGN_WORKFLOW}`;
    }

    userLandingPageBasedOnUserScopes = await this.permissionsService.hasPermission('campaign-performance-insights-viewer');
    if (userLandingPageBasedOnUserScopes) {
      return `/${MainNavigationEnum.CPI}`;
    }

    userLandingPageBasedOnUserScopes = await this.permissionsService.hasPermission('consumer-profile-viewer');
    if (userLandingPageBasedOnUserScopes) {
      return `/${MainNavigationEnum.LOOKUP}`;
    }

    userLandingPageBasedOnUserScopes = await this.permissionsService.hasPermission('app-integrations-admin');
    if (userLandingPageBasedOnUserScopes) {
      return `/${MainNavigationEnum.APPLICATION_INTEGRATIONS}`;
    }

    // TODO: set error page
    return `/${MainNavigationEnum.UNAUTHORIZED}`;
  }
}
