import { Component, HostListener, Inject } from '@angular/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
})

@HostListener('window:scroll')

export class BackToTopComponent  {
  constructor(@Inject('Window') private window: Window) {}
  /**
 * This component is handles the back to top navigation for page scroll.
 * It will show/hide back to top image button based based 30% of the page height.
 * On page init this back to top image will be hidden by default.
 * This listen the broadcast event raise from app.component.
 */

  showBackToTop: boolean = false;
  topPosToStartShowing = 500;

  @HostListener('window:scroll')
  checkScroll() {
      
    // window scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = this.window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;   
    this.showBackToTop = scrollPosition >= this.topPosToStartShowing;
  }

  // TODO: Cross browsing
  scrollToTop() {
    this.window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth',
    });
  }

}
