import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

import { url } from './url-validators';

@Directive({
  selector: '[appUrlValidate]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => UrlValidator),
    multi: true,
  }],
})
export class UrlValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return url(control);
  }
}