// Core Modules
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

// External Modules
import { Observable, catchError, of, switchMap } from 'rxjs';

// Services
import { AuthService } from '../services/auth.service';

/**
 * Federated Domain Resolver
 */

export const FederatedDomainResolver : ResolveFn<string[]> = (): Observable<Array<string>> => {
  /**
   * The constructor method
   * @param auth authentication
   * @returns legacy federated domains
   */

  const authService = inject(AuthService);
  return authService.getLegacyFederatedDomains().pipe(
    switchMap((domain: Array<string>) => of(domain)),
    catchError(() => of([])));
};
