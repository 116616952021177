<div class="modal-header audience-modal-header">
  <h3 class="modal-title confirm-modal-header-title font-size-20" id="sh-ea-confirm-mdl-title">Confirm Navigation</h3>
  <button type="button" class="btn-close" aria-label="Close" id="sh-ea-confirm-mdl-icn-close" (click)="dismissModal('Close')"></button>
</div>
<div class="modal-body confirm-modal-body font-size-16 border-top py-3">
  <p id="sh-ea-confirm-mdl-primary-msg" class="mb-2">You have unsaved changes to this audience.</p>
  <p id="sh-ea-confirm-mdl-secondary-msg" class="mb-2">Are you sure you want to leave this page?</p>
</div>
<div class="modal-footer d-flex justify-content-between border-top">
  <button type="button" id="sh-ea-confirm-mdl-cancel-btn" class="btn btn-primary-hollow" (click)="dismissModal('Cancel')">Cancel</button>
  <button type="button" id="sh-ea-confirm-mdl-yes-btn" class="btn btn-primary" (click)="confirm()">Confirm</button>
</div>