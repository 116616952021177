<div class="modal-body py-3">
	<div class="row">
		<h3 class="modal-header mb-0">Session Timeout</h3>
		<div class="col-12 text-center">
			<hr class="modal-divider mt-0">
			<p><img class="pe-2" src="assets/images/clock.svg" />Your session will log out in</p>
			<h1 >{{expireInSeconds}}<span *ngIf="expireInSeconds>0" class="d-inline-block p-1">  seconds</span></h1>
			<p class="mb-0">Currently logged in as: <span class="fw-bold current-user-name">{{userName}}</span></p>
			<p>Select "Continue" to continue your session.</p>
			<hr class="modal-divider mt-2 mb-0">
		</div>
	</div>
</div>
<div class="modal-footer text-center pt-0">
	<button type="button" class="btn btn-primary" (click)="continue()">Continue</button>
	<button type="button" class="btn btn-primary-hollow" (click)="forceLogout()">Logout</button>
</div>