<div *ngIf="(authService.isAuthenticationInProgress$ | async) === false" class="flex-cols d-flex w-100 login-container bg-white">
    <div class="wmdi-login-background col-flex d-flex justify-content-center align-items-center">
        <img alt="WebMD Ignite Growth Platform Logo" src='assets/images/wmdi-growth-platform-logo.svg' class="img-fluid">
    </div>
    <div class="auth0-lock-container col-flex justify-content-center align-items-center">
        <div class="row h-100 d-flex justify-content-center align-items-center">
            <div class="col-12">
                <div class="card border-0 text-center mb-1">
                    <img class="wmdi-logo-container rounded img-fluid p-2" src="assets/images/webmd-ignite-icon.svg" alt="WebMD Ignite Growth Platform logo">
                </div>
                <!-- Client List Panel -->
                <div class="card client-info" id="clientListPanel" *ngIf="clients.length>1">
                    <h1 class="card-title font-size-24 d-flex justify-content-center align-items-center fw-bold">Log in</h1>
                    <div class="card-body overflow-auto pt-0" [ngClass]="{'client-list-card' : clients.length>5}">
                        <ul class="nav nav-pills flex-column">
                            <li *ngFor="let client of clients" class="nav-item border" (click)="selectClient(client)">
                                <a class="nav-link" href="javascript:void(0)">
                                    <span class="client-name">{{client.clientName}}</span>
                                    <i class="bi bi-chevron-right float-end material-icons"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>