// Core Modules
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

// External Modules
import { Subscription, debounceTime, fromEvent, map } from 'rxjs';

// Model
import { ISearchConfigModel } from '../models/search/search-config.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  public searchTerm: string = '';
  @Input() isLoading: boolean = false;
  @ViewChild('searchBox', { static: true }) searchInput!: ElementRef;
  
  // emit search term 
  @Output() searchTermEvent: EventEmitter<string> = new EventEmitter();

  /** 
   * Input variable for searchConfig 
  */
  @Input() searchConfig: ISearchConfigModel = {};

  private readonly subscription: Subscription = new Subscription();
  // Default configuration goes here
  private debounceTime: number = 0;
  public restrictedChars: number = 3;

  ngOnInit(): void {
    this.debounceTime = this.searchConfig.debounceTime || this.debounceTime;
    this.restrictedChars = this.searchConfig.restrictedCharsTo || this.restrictedChars;
    this.search();
  }

  private search() {
    this.subscription.add(fromEvent(this.searchInput.nativeElement, 'input')   //accepts a element,'click'
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(this.debounceTime),
      )
      .subscribe((data) => {
        if (this.searchTerm.length >= this.restrictedChars || data.length === 0) {
          this.searchTermEvent.emit(this.searchTerm);
        }
      }));
  }
  
  /**
   * method to clear searched term
   */
  clearSearch(): void {
    this.searchTerm = '';
    this.searchInput.nativeElement.value = '';
    this.searchTermEvent.emit(this.searchTerm);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
