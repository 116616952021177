/**
 * This enum is used for referring Local Storage Keys
 */
export enum LocalStorageEnum {
  AUTH0_DOMAIN = 'auth0_domain',
  USER_PROFILE = 'user_profile',
  EXPIRES_AT = 'expires_at',
  CONNECTION = 'auth0_connection',
  CAMPAIGN_TABS = 'campaign_overview_tab_config_',
  CAMPAIGN_ACTIVE_AUD = 'active_audience_',
  CAMPAIGN_ACTIVE_TACTICS = 'active_tactics_',
}