/**
 * Audience Insights Viewer Mapper
 * @returns audience insights viewer mapper
 */
export const audienceInsightsViewerMapper = () => {
  return {
    'audience-insights-viewer': ['audiences:read', 'app/audience:read', 'crm:read'],
    'audience-insights-create-audience': ['audiences:read', 'audiences:write', 'app/audience:read'],
    'audience-insights-read-template': ['audiences:read', 'app/audience:read', 'audiences:template:read'],
    'audience-insights-template-approver': [
      'audiences:read',
      'audiences:write',
      'app/audience:read',
      'audiences:template:read',
      'audiences:template:write',
      'audiences:template:approve',
    ],
    'audience-insights-template-submitter': [
      'audiences:read',
      'audiences:write',
      'app/audience:read',
      'audiences:template:read',
      'audiences:template:write',
    ],
    'export-audience-read': ['export/audience:read'],
    'demographic-report-write': ['report:write'],
    'custom-attributes-read': ['custom-attributes-api:read'],
    'audience-group-duplicate-read': ['audience/criteria:copy'],
  };
};
