<div class="d-flex justify-content-end search-bar position-relative">
  <input type="text" id="{{searchConfig.searchModelId}}" [(ngModel)]="searchTerm" #searchBox 
    placeholder={{searchConfig.placeHolder}} (ngModelChange)="isLoading=true" class="form-control p-1"/>
  <span *ngIf="!searchTerm && !isLoading" class="mh-material-icons form-control-feedback position-absolute">
    <i id="{{searchConfig.searchIconId || searchConfig.parentComponentName +'-search'}}" class="bi-search"></i>
  </span>
  <span *ngIf="searchTerm.length >= restrictedChars && isLoading" class="position-absolute form-control-feedback">
    <i id="{{searchConfig.loaderId || searchConfig.parentComponentName + '-loader'}}"
      class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></i>
  </span>
  <span *ngIf="searchTerm && !isLoading" class="mh-material-icons form-control-feedback position-absolute"
    (click)="clearSearch()">
    <a href="javascript:void(0)" class="link-secondary text-decoration-none">
      <i id="{{searchConfig.cancelIconId || searchConfig.parentComponentName + '-cancel'}}"
        class="bi-x-circle-fill"></i>
    </a>
  </span>
</div>