import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccessControlService } from '../../core/permission/service/access-control.service';
import { AuthService } from '../../core/services/auth.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

import { IAuth0ClientInfo, IClientList } from '../models/auth0-types.model';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Client List Component
 */
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss', '../auth-login/auth-login.component.scss'],
})
export class ClientListComponent implements OnInit, OnDestroy {

  /**
   * subscription to api calls
   */
  private readonly subscription = new Subscription();

  /**
   * The constructor method
   * @param authService auth service
   * @param router router
   * @param accessControlService access control service
   */
  constructor(
    public readonly authService: AuthService,
    private readonly accessControlService: AccessControlService,
    private readonly sessionManager: SessionManagerService,
    private readonly router: Router,
  ) { }

  /**
   * Method to get clients
   */
  get clients(): IAuth0ClientInfo[] {
    return this.authService.clientList;
  }

  /**
   * Method to set current client
   */
  set clients(clientList) {
    this.authService.clientList = clientList;
  }

  /**
   * The ngOnInit method
   */
  ngOnInit(): void {
    this.authService.authenticationInProgressSubject.next(true);
    this.subscription.add(this.authService.getClients().subscribe({
      next: (clients: IClientList)=> {
        this.authService.authenticationInProgressSubject.next(false);
        this.clients = clients.items;
      },
      error: (error: HttpErrorResponse) => {
        console.log(`<Client-list> - <ngOnInit> Error occurred during get clients...`, error);
        this.authService.authenticationInProgressSubject.next(false);
        this.sessionManager.stopWatching();
        this.authService.logout();
      },
    }));

  }

  /**
   * Selecting Client from ClientList
   * @param {IAuth0ClientInfo} client
   * @memberof AuthLoginComponent
   */
  selectClient(client: IAuth0ClientInfo) {
    this.authService.updateClientInfo(client);
    this.authService.authenticationInProgressSubject.next(true);
    // Load the permissions and get the landing page as per the user permission
    this.subscription.add(this.accessControlService.definePermissions().subscribe({
      next: ()=> {
        this.authService.sendDataToGainSights();
        this.accessControlService.getLandingPageBasedOnUserScopes().then((landingPage: string)=> this.router.navigate([landingPage]).then(() => this.authService.authenticationInProgressSubject.next(false)));
      },
      error: (e) => {
        this.authService.authenticationInProgressSubject.next(false);
        console.log(`<Client-list> - <selectClient> Error occurred during subscribing permissions...`, e);
        this.sessionManager.stopWatching();
        this.authService.logout();
      },
    }));
  }

  /**
   * The ngOnDestroy method to destroy subscription.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
