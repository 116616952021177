import { Component } from '@angular/core';
import { ConfigService } from '../../core/services/config.service';
import { FooterContentModel } from '../models/footer-content.model';

/**
 * Footer Component
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  /**
   * Holds the footer static contents
   */
  public footerContents: FooterContentModel;

  public copyText?: string;
  /**
   * The constructor method
   * @param configService config service
   */
  constructor(private configService: ConfigService) {
    // Load the footer static contents from app-contents.json file
    const currentYear = new Date().getFullYear();
    this.footerContents = this.configService.getContents('footer');
    this.copyText = `Copyright ${currentYear} ${this.footerContents.copyText}`;
  }

}
