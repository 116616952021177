import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, take } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { ConfigService } from '../../../core/services/config.service';
import { CryptoService } from '../../../core/services/crypto.service';
import { ErrorHandlerService } from '../../../core/services/error-handler.service';
import { AppEndpointsModel } from '../../models/endpoints.config.model';
import { AppEnvironmentConfig } from '../../models/environment-config.model';
import { ClientMarketingAutomationModel } from '../../models/client-marketing-automation.model';
import { environment } from '../../../../environments/environment';
import { IUserSettingsUsageReportURL } from '../../models/user-settings/user-settings-usage-report.model';
import { LocalStorageEnum } from '../../enum/local-storage-keys.enum';

/**
 * User Settings Service
 */
@Injectable()
export class UserSettingsService {
  /**
   * appEnvironment variable to hold the environment configurations
   */
  private appEnvironment: AppEnvironmentConfig;
  /**
   * appEndpoints to get app endpoints
   */
  private appEndpoints: AppEndpointsModel;
  
  /**
   * The constructor method
   * @param authService auth service
   * @param configService config service
   * @param cryptoService crypto service
   * @param errorHandlerService error handler service
   * @param http to make http calls
   */
  constructor(
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly cryptoService: CryptoService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly http: HttpClient) {
    this.appEnvironment = environment;
    this.appEndpoints = this.configService.getEndpointsByModule('appEndpoints');
  }

  /**
   * method to get usage report url
   * @returns Usage report url
   */
  getUsageReportUrl(): Observable<IUserSettingsUsageReportURL> {
    const API_URL = `${this.appEnvironment.API}${this.appEndpoints.usageReport}`;
    return this.http.get<IUserSettingsUsageReportURL>(API_URL)
      .pipe(
        take(1),
        map((data: IUserSettingsUsageReportURL) => data), 
        catchError(this.errorHandlerService.errorHandler),
      );
  }

  /**
   * method to get marketing automation config
   * @returns marketing automation config
   */
  getMarketingAutomationConfig(): Observable<ClientMarketingAutomationModel[]> {
    const API_URL = `${this.appEnvironment.API}${this.appEndpoints.marketingAutomation}`;
    return this.http.get<ClientMarketingAutomationModel[]>(API_URL)
      .pipe(
        take(1),
        map((data: ClientMarketingAutomationModel[]) => data), 
        catchError(this.errorHandlerService.errorHandler),
      );
  }

  /**
   * method to get eloqua url
   * @returns eloqua url
   */
  getEloquaURL(): string {
    const auth0Domain = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN);
    if (auth0Domain && this.appEnvironment.SAML_PROVIDER && this.appEnvironment.ELOQUA_CLIENT_ID) {
      let uriParams = `?hg-environment=${this.appEnvironment.HG_ENVIRONMENT}`;
      const userDetails = this.authService.getUserProfile();
      if (userDetails && userDetails['hg-customer']) {
        const hgCustomerCode = this.cryptoService.decrypt(String(userDetails['hg-customer'].clientId));
        uriParams += `&hg-customer-code=${hgCustomerCode}`;
      }
      const eloquaLink = `https://${auth0Domain}/${this.appEnvironment.SAML_PROVIDER}/${this.appEnvironment.ELOQUA_CLIENT_ID}${uriParams}`;
      return eloquaLink;
    } else {
      return '';
    }
  }

}