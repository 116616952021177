import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appInputValidator]',
})
export class InputValidatorDirective {

  @Input() maxLength!: number;
  @Input() originalValue!: number;
  @Output() order = new EventEmitter();
  private value!: string;

  constructor(private elementRef: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    this.value = this.elementRef.nativeElement.value;
    if (this.value && Number(this.value) > 0 && Number(this.value) <= Number(this.maxLength)) {
      this.elementRef.nativeElement.value = this.value.replace(/[^0-9]*/g, '');
      if (this.value !== this.elementRef.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this.value && Number(this.value) > 0 && Number(this.value) <= Number(this.maxLength)) {
      this.order.emit(this.value);
    } else {
      this.elementRef.nativeElement.value = Number(this.originalValue);
    }
  }

}
