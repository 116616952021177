import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { ErrorHandlerCodesEnum } from '../../pem-shared/enum/error-handler-codes.enum';
import { GenericErrorHandlerModel } from '../../pem-shared/models/generic-error-handler.model';
import appContents from '../../../assets/app-contents.json';

/**
 * Error Handler Service
 */
@Injectable()
export class ErrorHandlerService {
  /**
   * Load all the app constants into a variables
   */
  private contents: Record<string, any>;
  /**
   * The constructor method
   */
  constructor() {
    this.contents = appContents;
  }

  /** Error Handling method */
  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`);
    }
    // return an observable with a user-facing error message
    return throwError(() => error);
  }

  /**
   * This method describes the image, title and description of error page depanding on error code or shows default message if error code is not preset
   * @param code describes error code number for detrmining error page data
   * @returns GenericErrorHandlerModel to show image , title and description as per error code
   */
  getErrorCodeData(code: number | string): GenericErrorHandlerModel {
    const errorCodeData = code === ErrorHandlerCodesEnum.FORBIDDEN ? this.contents.errorMessageHandler.accessDenied : this.contents.errorMessageHandler.defaultMessage;
    return errorCodeData;
  }
}
