import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleConfig, UserIdleService } from 'angular-user-idle';
import {
  catchError,
  filter,
  fromEvent,
  lastValueFrom,
  map,
  merge,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import { AuthService } from './auth.service';

import { SessionExpiryNotificationComponent } from '../../pem-shared/session-expiry-notification/session-expiry-notification.component';

import { environment } from '../../../environments/environment';

import { MainNavigationEnum } from '../../pem-shared/enum/main-navigation-url.enum';
import { UserProfileFieldsEnum } from '../../pem-shared/enum/user-profile-fields.enum';


/**
 * Session Manager Service
 */
@Injectable({
  providedIn: 'root',
})
export class SessionManagerService implements OnDestroy {
  /**
   * modalOption to get modal options
   */
  private modalOption: NgbModalOptions = {};

  /**
   * subscription to User Idle Ping and Refresh, check session call
   */
  private readonly subscription = new Subscription();

  /**
   * The constructor method
   */
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly userIdle: UserIdleService,
  ) { }

  /**
   * This method is used to indicate the user that currently in user idle state.
   *
   * @memberof SessionManagerService
   */
  sessionExpiryNotifier(): void {
    this.modalOption = {
      backdrop: 'static',
      keyboard: false,
      backdropClass: 'modal-backdrop-opacity',
      windowClass: 'session-notifier-modal',
    };
    const logoutNotifierModalRef = this.modalService.open(SessionExpiryNotificationComponent, this.modalOption);
    const userEmail = this.auth.getUserProfileBasedOnFields([UserProfileFieldsEnum.EMAIL]).email;
    logoutNotifierModalRef.componentInstance.userName = userEmail ? userEmail : '';
    logoutNotifierModalRef.result.then((result) => {
      if (result === 'logout') {
        this.stopWatching();
        this.auth.logout();
      } else {
        this.userIdle.stopTimer();
        this.subscription.add(this.auth.refreshSession().subscribe({
          next: () => {
            console.log('<Session-Manager> <sessionExpiryNotifier> :: Resetting UserIdle Timer :');
            this.userIdle.resetTimer();
          },
          error: (error) => {
            console.log('<Session-Manager> <sessionExpiryNotifier>: Failed while refreshing the session', error);
            this.stopWatching();
            this.auth.logout();
          },
        }));
      }
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
      this.stopWatching();
      this.auth.logout();
    });
  }

  /**
   * This method is helper method for ngBootstrap modal window to know the Dismiss Reason
   * Whether ESC Key is pressed or user clicked continue or logout button
   * @private
   * @param {*} reason
   * @return {*}  {string}
   * @memberof SessionManagerService
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'PRESS_ESC_BUTTON';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'BACKDROP_CLICK';
    } else {
      return `with: ${reason}`;
    }
  }

  /**
  * Periodically Polling for Session is Alive
  * The poll interval between checks to checkSession() should be at least 10 minutes
  * between calls to avoid any issues in the future with rate limiting of this call
  * @memberof SessionManagerService
  */
  setupSessionEvents(): void {
    const userIdleConfig: UserIdleConfig = this.userIdle.getConfigValue();
    console.log(`<Session-Manager> <setupSessionEvents> :: Current User Idle Configuration is : ${JSON.stringify(userIdleConfig)}, at ${new Date()}`);
    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    /*
    * Currently, angular user idle npm supports default interrupts DOM Events.
    * By using setCustomActivityEvents , angular user idle interrupts following DOM Events.
    */
    this.userIdle.setCustomActivityEvents(
      merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'resize'),
        fromEvent(document, 'keydown'),
        fromEvent(document, 'touchstart'),
        fromEvent(document, 'touchend'),
      ),
    );
    // Start watching for user inactivity.
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      console.log(`<Session-Manager> <setupSessionEvents> User Idle Timeout count :: ${new Date()}`, count);
      if (count === environment.SESSION.logoutNotifyExpiryTime) {
        console.log(`<Session-Manager> <setupSessionEvents> User Idle Timeout is Elapsed !... ${new Date()}`);
        this.sessionExpiryNotifier();
      } else if (count > (2 * environment.SESSION.logoutNotifyExpiryTime) + 10) {
        console.log(`<Session-Manager> <setupSessionEvents> Did not receive any input from user after Max User Idle Timeout. Force Logout on ${new Date()}`);
        this.stopWatching();
        this.auth.logout();
      }
    });

    /*
     *  Ping is used if you want to perform some action periodically every n-minutes in lifecycle of timer.
     *  1. Auth0 Check Session
     *  2. Refresh Token
     */
    this.subscription.add(this.userIdle.ping$.subscribe(async () => {
      console.log('PING', new Date());
      try {
        await lastValueFrom(this.auth.refreshSession().pipe(
          tap(() => console.log('<Session-Manager> <setupSessionEvents> After Token Refresh ....', new Date())),
        ).pipe(
          takeUntil(
            // stop polling on either logout
            merge(
              this.auth.logoutSubject$.pipe(filter(status => status)),
            ),
          ),
        ));
      } catch (error) {
        console.log('<Session-Manager> <setupSessionEvents> Failed while refreshing the session', error);
        this.stopWatching();
        this.auth.logout();
      }
    }));
  }

  /**
   * Stop the watching timer and unsubscribe the subscription
   */
  stopWatching(): void {
    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.subscription.unsubscribe();
    console.log('<Session-Manager> <stopWatching>: Unsubscribed the ping and refresh, check session calls');
  }
  /**
   * This utility method is used to intercept all the route on hook of canActivateRoute
   *
   * @return {*}
   * @memberof SessionManagerService
   */
  validateSessionBeforeCanActivateRoute(): Observable<boolean> {
    return this.auth.isSessionValid().pipe(
      take(1),
      switchMap((status) => {
        if (status) {
          console.log('<Session-Manager> <ValidateSessionBeforeCanActivateRoute>: current session status...', status);
          return of(true);
        } else {
          this.auth.authenticationInProgressSubject.next(true);
          this.auth.clientNavigationInProgressSubject.next(true);
          return lastValueFrom(this.auth.refreshSession().pipe(
            take(1),
            map(() => {
              this.auth.authenticationInProgressSubject.next(false);
              this.auth.clientNavigationInProgressSubject.next(false);
              console.log('<Session-Manager> <ValidateSessionBeforeCanActivateRoute>: After check session :', new Date());
              return true;
            })));
        }
      }),
      catchError((error) => {
        this.auth.authenticationInProgressSubject.next(false);
        this.auth.clientNavigationInProgressSubject.next(false);
        console.log('<Session-Manager> <ValidateSessionBeforeCanActivateRoute>: Failed while refreshing the session', error);
        this.stopWatching();
        this.auth.logout();
        return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
