// Angular Modules
import { Injectable } from '@angular/core';

// External Modules & Services
import { Observable } from 'rxjs';

// Services
import { ConfigService } from '../../../core/services/config.service';
import { HttpAdapterService } from '../../../core/services/http-adapter.service';

@Injectable({
  providedIn: 'root',
})
export class MultiSearchService {
  // Private Members
  private readonly applicationEndpoint: Record<string, object>;

  /**
   * The constructor method
   * @param configService A Service used to get default configurations
   * @param httpAdapterService A Service used to perform API calls based on parameters
   */
  constructor(
    private readonly configService: ConfigService, 
    private readonly httpAdapterService: HttpAdapterService,
  ) {
    this.applicationEndpoint = this.configService.getEndpointsByModule('applicationEndpoints');
  }

  public getMultiSelectValuesOnSearch<T>(searchFieldVal: string, key: string | string[], isMultiItem: boolean): Observable<T> {
    const criterialSearchEndPoint: string = `${this.configService.getAPIBaseUrl}${this.applicationEndpoint.application}/audience/criteria-search`;
    if (typeof key === 'string') key = [key];
    const multipleItems = isMultiItem || false;
    const payLoad = {
      multipleItems,
      searchField: searchFieldVal,
      searchTerms: key,
    };
    return this.httpAdapterService.post<T>(criterialSearchEndPoint, payLoad);
  }
}
