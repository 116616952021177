// Core Modules
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// Enums
import { LocalStorageEnum } from '../../pem-shared/enum/local-storage-keys.enum';
import { MainNavigationEnum } from '../../pem-shared/enum/main-navigation-url.enum';
import { UserProfileFieldsEnum } from '../../pem-shared/enum/user-profile-fields.enum';

/**
 * ME-4475
 * This Storage Tracker service is used for tracking the change of clients through user settings dropdown
 * on duplicate tabs. This will make sure the client selection is same across duplicated tabs (Have restriction of max 2 tabs).
 */
@Injectable({
  providedIn: 'root',
})
export class StorageTrackerService implements OnDestroy {

  constructor(
    private readonly router: Router,
    @Inject('Window') private readonly window: Window,
  ) {
    this.stop();
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }


  private start(): void {
    this.window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    // Reload the page on below conditions for multiple tabs open
    // 1. Reload on change in user_profile value in local storage
    // 2. Reload the page on clear of local storage
    if (event.storageArea === localStorage && this.window.location.href?.split('/').pop() !== 'tableau.html') {
      if ((event.key === null && event.newValue === null)) this.router.navigate([`/${MainNavigationEnum.LOGIN}`], { onSameUrlNavigation: 'reload' });
      if (((event.key === LocalStorageEnum.USER_PROFILE && (event.newValue && JSON.parse(event.newValue)[UserProfileFieldsEnum.HG_CUSTOMER])))) this.window.location.reload();
    }
  }

  public stop(): void {
    this.window.removeEventListener('storage', this.storageEventListener.bind(this));
  }
}
