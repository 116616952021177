import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unsaved-audience-confirm-modal',
  templateUrl: './unsaved-audience-confirm-modal.component.html',
})
export class UnsavedAudienceConfirmModalComponent {
  constructor(
    private readonly activeModal: NgbActiveModal,
  ) {}

  /**
   * dismissModal method is to dismiss the modal with reason
   * @param reason Why the modal is dismissed
   */
  public dismissModal(reason: string): void {
    this.activeModal.dismiss(reason);
  }

  /**
   * confirm method is to navigate the user to respective route the user is trying to access to
   */
  public confirm() {
    this.activeModal.close({ status: 'success' });
  }
}
