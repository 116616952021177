/**
 * Campaigns Performance Insights Mapper
 * @returns CPI mapper
 */
export const cpiMapper = () => {
  return {
    'campaign-performance-insights-viewer': ['app/dashboard:read', 'roi-partial-attribution:read', 'campaign:read', 'crm:read'],
    'campaign-performance-insights-export': ['export/dashboard:read'],
  };
};
  