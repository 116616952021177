// Core Modules
import { Component, ElementRef, OnInit, Renderer2, ViewChild, Input, Output, EventEmitter } from '@angular/core';

// Models
import { IScrollItem } from '../models/scroll-to-section/scroll-to-section-config.model';

@Component({
  selector: 'app-scroll-to-section',
  templateUrl: './scroll-to-section.component.html',
  styleUrls: ['./scroll-to-section.component.scss'],
})
export class ScrollToSectionComponent implements OnInit {
  @Input() scrollItem: IScrollItem[] = [];
  @Input() reportingParamExport: any;
  @Input() downloadPdf?: Record<string, any>;
  @Output() downloadCallBack: EventEmitter<Record<string, string>> = new EventEmitter<Record<string, string>>();
  @Output() callback: EventEmitter<Record<string, string>> = new EventEmitter<Record<string, string>>();
  @ViewChild('linkWrapper', { static: false }) linkWrapperRef: ElementRef | undefined;

  // Public Members
  public currentPosition: number = 0;
  public currentStatus: boolean = false;
  public nextBtn: boolean = false;
  public paddingSpace: number = 4;
  public parentContainer: any;
  public positionArray: any[] = [];
  public totalWidth: number = 0;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.nextBtn = false;
    this.currentPosition = 0;
    const linkWrapper = this.elRef.nativeElement.querySelector('#linkWrapper');
    if (linkWrapper) {
      this.renderer.setStyle(linkWrapper, 'left', '0');
      this.renderer.setStyle(linkWrapper, 'transition', 'none');
    }
    setTimeout(() => {
      const parentEl = this.elRef.nativeElement.querySelector('#linkParent');
      if (parentEl) {
        this.parentContainer = parentEl.getBoundingClientRect();
        this._calculateWidth();
      }
    }, 500);
  }

  public _calculateWidth() {
    this.positionArray = [];
    this.totalWidth = 0;

    this.scrollItem.forEach((key, index) => {
      const posEl = this.elRef.nativeElement.querySelector(`#pos${index}`);

      if (posEl) {
        const posProperties = this.getPosition(posEl);
        if (posProperties) {
          this.totalWidth = this.totalWidth + this.paddingSpace + posProperties.width;
        }
        if (this.totalWidth > this.parentContainer.width) {
          this.nextBtn = true;
        }
        this.positionArray.push(posProperties);
      }
    });
  }

  private getPosition(elRef: HTMLElement): { width: number, height?: number, top?: number, left?: number } | null {
    const rect = elRef.getBoundingClientRect();
    return {
      width: Math.round(rect.width),
      height: Math.round(rect.height),
      top: rect.top,
      left: rect.top,
    };
  }

  private _smoothScroll(position: { top: number }) {
    const currentScrollPos = window.scrollY || document.documentElement.scrollTop;
    const scrollTo = position.top + currentScrollPos - 145;
    window.scrollTo({
      left: 0,
      top: scrollTo,
      behavior: 'smooth',
    });
  }

  /**
   *  Scroll to respective section by passing the
   * id of the section to display
   */
  public gotoSection(elementId: string): void {
    const element: ElementRef | null = new ElementRef(document.querySelector(`#${elementId}`));

    if (element && element.nativeElement) {
      const boundingRect = element.nativeElement.getBoundingClientRect();
      const position = {
        top: boundingRect.top + window.scrollY,
        left: boundingRect.left + window.scrollX,
      };
      this._smoothScroll(position);
    }
  }

  public scrollTo(id: string, name: string, type: string | undefined): void {
    if (this.callback instanceof EventEmitter && typeof this.callback === 'function' && type) {
      this.callback.emit({ param: name, type });
    } else {
      this.gotoSection(id);
    }
  }

  public moveItem(type: string): void {
    const linkWrapper = this.elRef.nativeElement.querySelector('#linkWrapper');
    if (linkWrapper.style.transition.includes('none')) {
      linkWrapper.style.transition = 'all ease-in-out 0.7s';
    }
    if (type === 'left') {
      this.currentPosition++;
    } else {
      this.currentPosition--;
    }
    this._moveItemContainer();
  }

  public _moveItemContainer(): void {
    let left = 0;
    const currentPosition = Math.abs(this.currentPosition);
    this.positionArray.forEach((value, key) => {
      if (currentPosition === 0) {
        left = 0;
      } else {
        if (currentPosition > key) {
          left = left - this.positionArray[key].width;
        }
      }
    });
    this._nextBtnHandler(left);
    const linkWrapper = document.getElementById('linkWrapper');
    if (linkWrapper) {
      linkWrapper.style.left = left + 'px';
    }
  }

  public _nextBtnHandler(left: number): void {
    const containerPosition: number = Math.abs(left) + this.parentContainer.width + (this.paddingSpace * this.positionArray.length);
    this.nextBtn = containerPosition <= this.totalWidth;
  }
  
  public triggerDownloadCallback(param: Record<string, string>) {
    if (!this.currentStatus) {
      this.downloadCallBack.emit(param);
    }
  }

}
