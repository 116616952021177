// Core Modules
import { Injectable } from '@angular/core';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  /**
   * 
   * @param num number to be formatted
   * @returns formatted number 
   * @description  converts number into formatted number if type is extended with f (ex: amt-f, num-f) converts numbers into G,M,K format. Type values are
   * per = percentage, amt = amount, num = number returns the formatted data. 
   */
  public convertNumberToNotation(num: number, type: string = '', dec: number = 2): string {
    let affix: string = '', format: string = '';
    let number: string = '';
    [affix, format] = type.split('-');
    if (format === 'f') {
      if (num >= 1000000000) {
        number = (num / 1000000000).toFixed(dec).replace(/\.0$/, '') + 'B';
      } else if (num >= 1000000) {
        number = (num / 1000000).toFixed(dec).replace(/\.0$/, '') + 'M';
      } else if (num >= 1000) {
        const thousandsFormatted = (num / 1000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 2 }).replace(/\.0$/, '');
        number = num >= 1000 ? thousandsFormatted + 'K' : num.toLocaleString();
      } else {
        number = num.toLocaleString();
      }
    } else {
      number = num.toLocaleString();
    }
    switch (affix) {
      case 'per': number = number + '%'; break;
      case 'amt': number = '$' + number; break;
      case 'num': number = number;
    }
    return number;
  }

  /**
   * getUserSpecificTZ method is to get the timezone of the timestamp passed as parameter
   * @param timestamp the timeStamp for which timezone is required
   * @returns the user specific timezone abbreviations - IST, MST, CDT etc.
   */
  public getUserSpecificTZ(timestamp: string): string {
    // Find the timeZone in "00:00:00 GMT+0530 (India Standard Time)"
    const timeZone = RegExp(/\((.*)\)/).exec(new Date(timestamp).toTimeString());
    // ex: timeZone is "India Standard Time"
    // Split by ' ' and join the first letter of each parts to fetch the abbreviated timezone
    const abbreviatedTZ = timeZone?.length ? timeZone[1].split(' ').map((parts: string) => parts[0]).join('') : '';
    return abbreviatedTZ;
  }

  /**
   * @dateFormatter to convert given date into mm/dd/yy format and also cheks if date is today or yesterday. if date is today's date or yesterday's 
   * date then it will replace date with 'Today' or 'Yesterday'
   * @param date to format 
   * @returns formatted date
   */
  dateFormatter(date: string) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    const currentDate = new Date();
    const givenDate = new Date(date);
    const timeStart = new Date(givenDate.toISOString()).getTime();
    const timeEnd = new Date(currentDate.toISOString()).getTime();
    const hourDiffInMs = timeEnd - timeStart; // in ms
    const hourDiffInHours = hourDiffInMs / 3600 / 1000; // in hours
    const calculatedHours = Math.floor(hourDiffInHours);
    if (calculatedHours < 24) {
      if (Math.abs(currentDate.getDate() - givenDate.getDate()) > 1) {
        return 'Yesterday';
      } else {
        return 'Today';
      }
    } else if (calculatedHours < 48) {
      return 'Yesterday';
    }
    return formattedDate;
  }

  /**
   * @dateFormatter to convert given date into mm/dd/yy format.
   * @param date to format 
   * @returns formatted date
   */
  dateFormatterApplicationIntegrations(date: string | undefined | null) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = date ? new Date(date).toLocaleDateString('en-US', options) : '';
    return formattedDate;
  }

  twoDigitFormatter(digit: number) {
    return digit < 10 ? '0' + digit : '' + digit;
  }

  getUnique(arr: any[], comp: string | number) {
    const unique = arr
      .map((e: { [x: string]: any; }) => e[comp])

      // store the keys of the unique objects
      .map((e: any, i: any, final: string | any[]) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e]).map((e: string | number) => arr[e as any]);
    return unique;
  }

  // Removing special character from the phone no.
  removeSpecialCharacters(value: string): string {
    return value.replace(/[^\d]/g, '');
  }

  formatPhoneNumber(phone: string): string | null {
    // normalize string and remove all unnecessary characters
    phone = this.removeSpecialCharacters(phone);

    // check if number length equals to 10
    if (phone.length === 10) {
      // reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return null;
  }

  /**
   * getDismissReason method is to get the reason for modal closure
   * @param reason which is received while closure
   * @returns message based on reason
   */
  public getDismissReason(reason: string | number): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }  

}
