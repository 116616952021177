<div class="flex-grow-1 user-settings-container border-left">
    <div class="d-flex align-items-center justify-content-center flex-grow-1 ps-4" id="app-header-ic-us">
        <span class="rounded-circle d-flex align-items-center justify-content-center">
            <span class="d-inline-block fw-bold font-size-16" id="app-header-us-title-initials">{{userNameInitials}}</span>
        </span>
        <div ngbDropdown [autoClose]="'outside'" placement="bottom-end" class="user-settings-dropdown d-inline-block" (openChange)="initUserSettingsDropDownOptions($event)">
            <i class="bi bi-chevron-down d-inline-block" id="app-header-lnk-us" ngbDropdownToggle></i>
            <ul ngbDropdownMenu aria-labelledby="app-header-lnk-us">
                <li class="dropdown-item d-flex align-items-center">
                    <span class="rounded-circle d-flex align-items-center justify-content-center"
                        id="app-header-lnk-user-name">
                        <span class="d-inline-block fw-bold font-size-16 text-white" id="app-header-us-title-initials">{{userNameInitials}}</span>
                    </span>
                    <h6 class="d-inline-block ps-2 mb-0 font-size-14" id="app-header-us-mdl-useremail">{{userEmailName}}
                    </h6>
                </li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li class="dropdown-item" *ngIf="clientList.length>1">
                    <div class="select-custom my-2">
                        <select name="selectedClient" id="app-header-lnk-client-list" class="form-control font-size-14"
                            [(ngModel)]="selectedClient" (change)="changeClient()">
                            <option *ngFor="let clientDetail of clientList" [value]="clientDetail.clientName">
                                {{clientDetail.clientName}}</option>
                        </select>
                        <i class="p-1"><img src="assets/images/chevron-down.svg" /><span
                                class="visually-hidden-focusable font-size-12">Client List Arrow
                                Down</span></i>
                    </div>
                </li>
                <li *ngIf="!switchClientStatusIndicator">
                    <a *ngxPermissionsOnly="['app-integrations-admin', 'file-config-read', 'file-uploads-read']" class="dropdown-item link-primary" id="app-header-us-btn-admin"
                        (click)="navigateToRoute()" href="javascript:void(0)">{{headerContents.profileRedirectData.applicationIntegrations}}</a>
                </li>
                <li class="d-flex justify-content-center align-items-center" *ngIf="switchClientStatusIndicator">
                    <div class="spinner-border spinner-border-sm small m-3" role="status" id="app-header-us-spinner">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                </li>
                <li *ngIf="!switchClientStatusIndicator">
                    <a *ngxPermissionsOnly="['user-settings-market-automation']" class="dropdown-item link-primary" href="{{marketingAutomationUrl}}" target="_blank"
                        id="app-header-us-lnk-ma" [ngClass]="{'disabled': marketingAutomationUrl === ''}">Marketing
                        Automation</a>
                </li>
                <li *ngIf="!switchClientStatusIndicator && legacyHelpDesk">
                    <a id="app-header-us-lnk-kc" class="dropdown-item link-primary"
                        href="{{userSettingsEndpoints.helpCenterUrl}}"
                        target="_blank">{{headerContents.profileRedirectData.helpCenter}}-Legacy</a>
                </li>
                <li *ngIf="!switchClientStatusIndicator && !dXeHelpEnabled  && !legacyHelpDesk">
                    <a id="app-header-us-lnk-kc" class="dropdown-item link-primary"
                        href="{{userSettingsEndpoints.helpCenterUrl}}"
                        target="_blank">{{headerContents.profileRedirectData.helpCenter}}</a>
                </li>
                <li *ngIf="!switchClientStatusIndicator">
                    <a id="app-header-us-lnk-mc" class="dropdown-item link-primary" *ngIf="isConsoleLogin"
                        href="{{userSettingsEndpoints.mercuryConsoleUrl}}"
                        target="_blank">{{headerContents.profileRedirectData.mercuryConsole}} </a>
                </li>
                <li  *ngIf="!switchClientStatusIndicator">
                    <a *ngxPermissionsOnly="['user-settings-usage-report']" class="dropdown-item link-primary" id="app-header-us-lnk-usage-report"
                        (click)="downloadUsageReport()" [ngClass]="{'disabled': usageReportDownloadUrl === ''}"
                        href="javascript:void(0)">
                        Download Usage Report
                        <span *ngIf="usageReportDownloadUrl && reportCreatedDate">({{reportCreatedDate}})</span>
                    </a>
                </li>
                <li *ngIf="!switchClientStatusIndicator">
                    <a class="dropdown-item link-primary cursor-pointer" (click)="navigateToFeedbackPortal()">{{headerContents.profileRedirectData.productFeedbackPortal}}</a>
                </li>
                <li *ngIf="!switchClientStatusIndicator && ( dXeHelpEnabled) || legacyHelpDesk">
                    <a class="dropdown-item link-primary cursor-pointer"  target="_blank" (click)="navigateToHelpCenter()">{{headerContents.profileRedirectData.helpCenter}}</a>
                </li>

                <li>
                    <hr class="dropdown-divider">
                </li>
                <li class="text-center">
                    <a class="dropdown-item link-primary cursor-pointer fw-bold" id="app-header-us-btn-logout"
                        (click)="logout()">{{headerContents.profileRedirectData.logout}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
