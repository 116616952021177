import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfigService } from '../../core/services/config.service';

/**
 * Session Expiry Notification Component
 */
@Component({
  selector: 'app-session-expiry-notification',
  templateUrl: './session-expiry-notification.component.html',
  styleUrls: ['./session-expiry-notification.component.scss'],
})
export class SessionExpiryNotificationComponent implements OnInit, OnDestroy {
  /**
   * notifyTimerInterval to notify interval time for session
   */
  private notifyTimerInterval: any;
  /**
   * expireInSeconds to expire session in seconds
   */
  public expireInSeconds: number = 0;
  /**
   * userName to get user name
   */
  @Input() userName?: string;
  /**
   * checkTime to emitter for check time
   */
  @Output() checkTime: EventEmitter<number> = new EventEmitter();
  /**
   * The constructor method
   * @param configService config service
   * @param activeModal active modal
   */
  constructor(
    private readonly configService: ConfigService,
    private readonly activeModal: NgbActiveModal,
  ) {
    this.expireInSeconds = this.configService.logoutNotifyExpiryTime;
  }

  /**
   * The ngOnInit method
   */
  ngOnInit() {
    this.notifyTimerInterval = setInterval(() => {
      if (this.expireInSeconds > 0) {
        this.expireInSeconds--;
      } else {
        clearInterval(this.notifyTimerInterval);
        this.forceLogout();
      }
    }, 1000);
  }

  /**
   * method to show continue modal
   */
  continue() {
    clearInterval(this.notifyTimerInterval);
    this.activeModal.close('continue');
  }

  /** 
   * method to force logout
   */
  forceLogout() {
    clearInterval(this.notifyTimerInterval);
    this.activeModal.close('logout');
  }

  /**
   * method to destroy time interval
   */
  ngOnDestroy() {
    clearInterval(this.notifyTimerInterval);
  }

}
