import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ITag } from '../models/checkbox-tree/checkbox-tree.model';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  @Input() parent!: string;
  @Input() idPrefix!: string;
  @Input() itemConfig:ITag[] = [];
  @Input() mapController!: boolean;
  @Output() itemRemoved = new EventEmitter<ITag>();
  @Input() configOptions: Record<string, any> = { key: 'code', value :'description' };

  deselectAttribute(tag:ITag) {
    if (this.parent) tag.consumer = this.parent;
    if (this.mapController) {
      this.itemRemoved.emit(tag);
    } else {
      const index: number = this.itemConfig.findIndex(item => item[this.configOptions.key] === tag[this.configOptions.key]);
      if (index > -1) {
        this.itemConfig.splice(index, 1);
        tag.index = index;
        this.itemRemoved.emit(tag);
      }
    }
  }

}
