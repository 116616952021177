import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { ErrorHandlerCodesEnum } from '../enum/error-handler-codes.enum';

import { GenericErrorHandlerModel } from '../models/generic-error-handler.model';

/**
 * Unauthorized Component
 */
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  /**
   * accessDeniedStatusCode to get access denied status code
   */
  private accessDeniedStatusCode: number = ErrorHandlerCodesEnum.FORBIDDEN;

  /**
   * contains data of type GenericErrorHandlerModel to show image , title and description
   */
  public errorhandlerData?: GenericErrorHandlerModel;

  /**
   * The constructor method
   * @param errorHandlerService error handler service
   */
  constructor(private errorHandlerService: ErrorHandlerService) {
  }

  /**
   * The ngOnInit method
   */
  ngOnInit(): void {
    this.errorhandlerData = this.errorHandlerService.getErrorCodeData(this.accessDeniedStatusCode);
  }
}
