/**
 * Market Planner Insights Viewer Mapper
 * @returns Market Planner Insights Viewer
 */
export const marketPlannerInsightsViewerMapper = () => {
  return {
    'standard-report-viewer': [
      'reports-session/link:read',
      'reports-session/standard-report:read',
      'integrations:client-ma-preference:read',
      'ref:read',
    ],
    'custom-reports-viewer': [
      'reports-session/custom-link:read',
      'integrations:client-ma-preference:read',
      'ref:read',
    ],
    'consumer-iq-report-viewer': [
      'reports-session/consumer-iq-report:read',
      'reports-session/link:read',
    ],
    'retention-iq-report-viewer': [
      'reports-session/retention-iq-report:read',
      'reports-session/link:read',
    ],
  };
};
  