// Core modules
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tableau-portal',
  templateUrl: './tableau-portal.component.html',
  styleUrls: ['./tableau-portal.component.scss'],
})
export class TableauPortalComponent implements OnInit {

  @Input() tableauSamlUrl: any;
  @Input() loader: boolean = true;
  @Input() error: boolean = false;

  constructor(private sanitizer: DomSanitizer) { }

  public ngOnInit(): void {
    this.tableauSamlUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.tableauSamlUrl);
  }

}
