<div class="pagination-wrapper" *ngIf="currentPageTotalRecords">
  <div class="d-inline-block" *ngIf="config.pageSizeOptions.length > 1">
    <span class="pagination-text">Items per page:</span>
  </div>
  <div class="mx-lg-2 select-custom d-inline-block" *ngIf="config.pageSizeOptions.length > 1">
    <select id="{{config.paginationLabelConfig.pageSizeSelector.id}}" class="form-control"
      [(ngModel)]="pageOverview.pageSize" (change)="pageSizeChangeHandler()">
      <option *ngFor="let page of config.pageSizeOptions" [ngValue]="page.value" [selected]="page.value">
        {{page.value}}
      </option>
    </select>
    <i class="bi bi-caret-down-fill py-1"></i>
  </div>
  <div class="d-inline-block">
    <nav [attr.aria-label]="config.paginationLabelConfig.maxPageIndicator.ariaLabel">
      <div class="pagination page-item">
        <span id="{{ config.paginationLabelConfig.maxPageIndicator.id }}"
          class="d-inline-block pagination-text px-3">{{pageOverview.num}} of
          {{pageOverview.maxPage}}
        </span>
      </div>
    </nav>
  </div>
  <nav [attr.aria-label]="config.paginationLabelConfig.pageNavigationIndicator.ariaLabel"
    [attr.id]="config.paginationLabelConfig.pageNavigationIndicator.id" *ngIf="currentPageTotalRecords>0"
    class="d-inline-block">
    <ul class="pagination align-items-center justify-content-center m-0">
      <li class="page-item cursor-pointer"
        [ngClass]="{'disabled': pageOverview.num === 1 || currentPageTotalRecords===0}"
        (click)="pageChangeHandler({action: 'first'})">
        <i class="bi bi-chevron-double-left arrow-asset p-1 py-2">
          <span class="visually-hidden">Double Arrow Back</span>
        </i>
      </li>
      <li class="page-item cursor-pointer px-1"
        [ngClass]="{'disabled': pageOverview.num === 1 || currentPageTotalRecords === 0}"
        (click)="pageChangeHandler({action: 'prev'})">
        <i class="bi bi-chevron-left arrow-asset">
          <span class="visually-hidden">Go to previous page</span>
        </i>
      </li>
      <div>
        <input id="pagination-go-to-page-top" type="number"
          class="form-control pagination-input-field text-center" [ngModel]="pageOverview!.num"
          (keydown.enter)="callbackRedirectPage($event)" />
      </div>
      <li class="page-item cursor-pointer px-1" [ngClass]="{'disabled':!pageOverview.hasNext}"
        (click)="pageChangeHandler({ action: 'next'})">
        <i class="bi bi-chevron-right arrow-asset">
          <span class="visually-hidden">Go to next page</span>
        </i>
      </li>
      <li class="page-item cursor-pointer" [ngClass]="{'disabled':!pageOverview.hasNext}"
        (click)="pageChangeHandler({ action: 'last'})">
        <i class="bi bi-chevron-double-right arrow-asset">
          <span class="visually-hidden">Double Arrow Right</span>
        </i>
      </li>
    </ul>
  </nav>
</div>
