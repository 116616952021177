import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-restricted-page',
  templateUrl: './manage-restricted-page.component.html',
  styleUrls: [],
})
export class ManageRestrictedPageComponent {

}
