// Angular Modules
import { Inject, Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

// External Modules & Services
import { Observable, catchError, finalize, lastValueFrom, switchMap, take, tap } from 'rxjs';

// Enums & Models
import { IUserProfileWithClientList } from '../../../app/pem-shared/models/auth0-types.model';
import { MainNavigationEnum } from '../../../app/pem-shared/enum/main-navigation-url.enum';

// Services
import { AccessControlService } from '../permission/service/access-control.service';
import { AuthService } from '../services/auth.service';
import { SessionManagerService } from '../services/session-manager.service';

/**
 * Auth Guard
 */
@Injectable()
export class AuthGuardCanActivate {
  /**
   * The constructor method
   * @param authService auth Service
   * @param router router
   * @param sessionManager SessionManagerService
   * @param window window
   */
  constructor(
    private readonly accessControlService: AccessControlService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly sessionManager: SessionManagerService,
    @Inject('Window') private window: Window,
  ) { }

  /**
   * method to validate activated route
   * @returns session valid boolean: true/false
   */
  canActivate(): Observable<boolean | UrlTree> {
    /** 
     * When we are logging into IGP by using SSO through launch web app, obtaining access token as part of query param in growth platform
     * Step 1: Get auth0 user profile by using access token
     * Step 2: Navigate to landing page route  
     *
    */  
    if (this.window.location.href.includes('auth0_domain') && this.window.location.href.includes('access_token')) {
      const urlSearchParam: URLSearchParams = new URLSearchParams(this.window.location.search);
      const urlHashParam: URLSearchParams = new URLSearchParams(this.window.location.hash);
      this.authService.authenticationInProgressSubject.next(true);
      const auth0Domain: string = urlSearchParam.get('auth0_domain') || '';
      localStorage.setItem('auth0_domain', auth0Domain);
      const customerId: string = urlSearchParam.get('customer_id') || '';
      const accessToken: string = urlHashParam.get('#access_token') || '';
      return this.loginByUsingAccessToken(auth0Domain, accessToken, customerId);
    } else if (this.window.location.href.includes('id_token') || this.window.location.href.includes('access_token') || this.window.location.href.includes('error')) {
      /** 
      * Logging into IGP by using Auth0 Lock widget
      * Step 1: Check session is valid
      * Step 2: Invoke Auth0 resume-auth method to parse the hash
      * Step 3: Navigate to landing page route   
      */
      const urlHrefParam: URLSearchParams = new URLSearchParams(this.window.location.href);
      const stateParam = urlHrefParam.get('state') as string;
      this.authService.authenticationInProgressSubject.next(true);
      return this.authService.isSessionValid().pipe(
        switchMap((status: boolean) => this.authService.resumeAuth(status, stateParam).pipe(
          tap(() => this.authService.authenticationInProgressSubject.next(true)),
          switchMap((userProfileWithClientList: IUserProfileWithClientList) => this.authService.loginWithRedirect(userProfileWithClientList, stateParam)),
          switchMap((navigateTo: string) => {
            console.log(`<AuthGuard> - <canActivate> - relayState is: ${stateParam}`);
            if (!['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
              this.sessionManager.setupSessionEvents();
            } else if (['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
              this.sessionManager.stopWatching();
              this.authService.logout();
            }
            return this.router.navigate([navigateTo]);
          }),
          catchError((error: Error) => {
            this.authService.authenticationInProgressSubject.next(false);
            console.log('<AuthGuard> - <canActivate> - Failed while resuming the auth Session', error);
            this.sessionManager.stopWatching();
            this.authService.logout();
            return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]); 
          }),
          finalize(() => {
            this.authService.authenticationInProgressSubject.next(false);
          }),
        )),
      );
    } else {
      return this.authService.isSessionValid().pipe(
        take(1),
        switchMap((status: boolean) => {
          if (status) {
            this.authService.authenticationInProgressSubject.next(true);
            return lastValueFrom(this.authService.refreshSession().pipe(
              take(1),
              switchMap(() => this.accessControlService.definePermissions().pipe(
                switchMap(() => {
                  this.sessionManager.setupSessionEvents();
                  return this.accessControlService.getLandingPageBasedOnUserScopes().then((landingPage:string) => this.router.navigateByUrl(landingPage)); 
                }),
              )),
              catchError((error: Error) => {
                this.authService.authenticationInProgressSubject.next(false);
                console.log('<AuthGuard> - <canActivate> - Failed while refreshing the session and navigating to specific page based on scope', error);
                this.sessionManager.stopWatching();
                this.authService.logout();
                return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]);
              }),
              finalize(() => this.authService.authenticationInProgressSubject.next(false)),
            )); 
          } else {
            this.authService.authenticationInProgressSubject.next(false);
            return Promise.resolve(true);
          }
        }),
      );
    }
  }

  /**
   *
   * @private
   * @param {string} auth0Domain
   * @param {string} accessToken
   * @param {string} customerId
   * @return {*} 
   * @memberof AuthGuardCanActivate
  */
  private loginByUsingAccessToken(auth0Domain: string, accessToken: string, customerId: string) {
    return this.authService.checkSessionByAccessToken(auth0Domain, accessToken, customerId)
      .pipe(
        switchMap((navigateTo) => {
          if (!['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
            this.sessionManager.setupSessionEvents();
          } else if (['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
            this.sessionManager.stopWatching();
            this.authService.logout();
          }
          return this.router.navigate([navigateTo]);
        }),
        catchError((error: Error) => {
          this.authService.authenticationInProgressSubject.next(false);
          console.log('<AuthGuard> - <canActivate> - Failed while resuming the auth Session', error);
          this.sessionManager.stopWatching();
          this.authService.logout();
          return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]);
        }),
        finalize(() => {
          this.authService.authenticationInProgressSubject.next(false);
        }),
      );
  }
}

export const AuthGuard: CanActivateFn = () => inject(AuthGuardCanActivate).canActivate();
