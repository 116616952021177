// Core Modules
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// External Modules
import { BehaviorSubject, catchError, forkJoin, from, Observable, of, switchMap, tap } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import _ from 'lodash';

// Services
import { AuthService } from '../../../app/core/services/auth.service';
import { ConfigService } from '../../../app/core/services/config.service';
import { HttpAdapterService } from '../../../app/core/services/http-adapter.service';
import { SegmentQueryService } from '../../audience/services/segment-query.service';

// Models
import { IAudienceModel, TCriteria, TCriteriaList, TInvalidCriteriaList, TCriteriaValidationPath, TCriteriaPathConditionValue, TGeoBoundingBox, TCustomAttributeResponse, GeoBoundary, TRiskPreviewModel } from '../../audience/models/audience-config.model';
import { TAudienceCriteria, TCriteriaChildren, TCriteriaQuery, TCustomAttributeCriteriaItemModel, TCustomAttributeCriteriaModel } from '../../audience/models/criteria.model';

// Enums
import { AudienceCacheEnum } from '../../pem-shared/enum/cache.enum';
import { ActivityTypeEnum, AudienceEvents, AudienceTypeEnum, CriteriaFilterTypeEnum, CriteriaIdEnum, CriteriaTypeEnum, SegmentMetricsChartTypeEnum } from '../../audience/enums/audience.enum';

// Environment
import { environment } from '../../../environments/environment';
import { ChannelKey } from '../../campaign-workflow/enum/campaign-workflow.enum';
import { TreeNode } from 'src/app/pem-shared/helpers/tree';
import { RiskModelsPayload } from '../templates/launch-model-selector/launch-model-selector.config.model';
import { ICheckBox } from 'src/app/common-components/multi-select/multi-select.config.model';

// Component
import { UnsavedAudienceConfirmModalComponent } from '../../audience/unsaved-audience-confirm-modal/unsaved-audience-confirm-modal.component';


@Injectable({
  providedIn: 'root',
})
export class EditAudienceService {
  // Private Members
  private unsavedAudienceModalRef!: NgbModalRef;
  private readonly activityTypes = [
    ActivityTypeEnum.CALL,
    ActivityTypeEnum.CALL_CONTENTREQUEST,
    ActivityTypeEnum.CALL_EVENTREGISTRATION,
    ActivityTypeEnum.CALL_PROVIDERREFERRAL,
    ActivityTypeEnum.CALL_SERVICEREFERRAL,
    ActivityTypeEnum.CUSTOM_ACTIVITY,
    ActivityTypeEnum.DIRECTMAIL_SEND,
    ActivityTypeEnum.EMAIL_CLICK,
    ActivityTypeEnum.EMAIL_OPEN,
    ActivityTypeEnum.EMAIL_SEND,
    ActivityTypeEnum.FORM_SUBMIT,
    ActivityTypeEnum.HRA_SUBMIT,
    ActivityTypeEnum.WEB_VISIT,
  ];
  private readonly apiBaseUrl: string;
  private readonly associatedChannels: string[] = [ChannelKey.DIRECT_MAIL, ChannelKey.DISPLAY, ChannelKey.EMAIL, ChannelKey.OUTBOUND_CALL, ChannelKey.PAID_SEARCH];
  private readonly audienceEndpoint: Record<string, object>;
  private readonly clientId: string = '';
  private enableCorpHierarchy: boolean = false;
  private readonly internalTemplateExclusions: string[] = [CriteriaIdEnum.SERVICE_LINE, CriteriaIdEnum.SUB_SERVICE_LINE];

  //BehaviorSubject
  public audienceChangeDetectSubject: BehaviorSubject<{ event: string, args: any }> = new BehaviorSubject({ event: '', args: null });
  public populationMetricsUpdateSubject:  BehaviorSubject<{ event: string }> = new BehaviorSubject({ event: '' });
  public criteriaListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isAddCriteriaButtonClicked: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public riskPreviewModelSubject: BehaviorSubject<TRiskPreviewModel | null> = new BehaviorSubject<TRiskPreviewModel | null>(null);
  public isRiskPreviewModelOpen:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public overallPopulationSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  public facilitiesSubject: BehaviorSubject<Partial<TreeNode>> = new BehaviorSubject<Partial<TreeNode>>({});
  public dropListContainer: BehaviorSubject<number> = new BehaviorSubject(1);

  // Public Members
  public criteriaList: Array<TCriteria> = [];
  public criteriaListError: boolean = false;
  public criteriaListLoader: boolean = false;
  public hasLkpAccess = true;
  public isLkpEnabled: boolean = false;
  public initialEighteenCheck: boolean = false;
  public LkpValidCriteriaList: string[] = [];
  public nonClientCriteriaList: string[] = [];
  public isExclusion:boolean = false;
  public tabDetails:string = '';
  public audienceType: string = '';
  public showNaModal: boolean = false;
  public isCriteriaUnsaved:boolean = false;
  public hasPermissionAccess = false;
  public isTemplateView: boolean = false;
  public view: string = 'metrics';

  constructor(
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly httpAdapterService: HttpAdapterService,
    private readonly ngxPermissionService: NgxPermissionsService,
    private readonly segmentQueryService: SegmentQueryService,
    private readonly modalService: NgbModal,
    private readonly router: Router,
  ) {
    this.apiBaseUrl = this.configService.getAPIBaseUrl;
    this.audienceEndpoint = this.configService.getEndpointsByModule('audienceEndpoints');
    this.enableCorpHierarchy = environment.ENABLE_CORP_HIERARCHY;
    this.ngxPermissionService.hasPermission('hasLkpAccess').then((status: boolean) => {
      this.hasLkpAccess = status;
    });
    this.clientId = this.authService.getClientId();
  }



  /**
   * getCriteriaList method is to fetch criteria details
   * sets the criteria details to subject.
   */
  public getTransformedCriteriaList(): Observable<[TCriteriaList, TCustomAttributeCriteriaModel] | null> {
    return forkJoin([this.getCriteriaList(), from(this.ngxPermissionService.hasPermission('custom-attributes-read')).pipe(
      switchMap((customAttributePermission) => customAttributePermission ? this.getCustomAttributeCriteria() : of({ items: [] })),
    )]).pipe(
      tap(([criteriaList, customAttributeCriteria]: [TCriteriaList, TCustomAttributeCriteriaModel]) => {
        this.criteriaListError = false;
        this.criteriaListLoader = false;
        this.transformCriteriaItems(criteriaList, customAttributeCriteria?.items);
        this.audienceChangeDetectSubject.next({ event: AudienceEvents.LOAD_NA_DATA, args: 'loadNaData' });
      }),
      catchError((err: HttpErrorResponse) => {
        console.log('<EditAudienceComponent> - <getCriteriaList> : Error occurred while fetching criteria list', err);
        this.criteriaListError = true;
        this.criteriaListLoader = false;
        return of(null);
      }),
    );
  }



  public getCriteriaList(): Observable<TCriteriaList> {
    const cacheKey = `${AudienceCacheEnum.CRITERIA_LIST_ITEMS}${this.clientId}${this.enableCorpHierarchy}`;
    const endpointUrl = `${this.apiBaseUrl}${this.audienceEndpoint.criteriaList}?enableCorpHierarchy=true`;
    return this.httpAdapterService.get<TCriteriaList>(endpointUrl, {}, cacheKey);
  }

  public getInvalidCriteriaList(audienceId: string): Observable<TInvalidCriteriaList> {
    const endpointUrl = `${this.apiBaseUrl}${this.audienceEndpoint.validateJson}/${audienceId}?enableCorpHierarchy=${this.enableCorpHierarchy}`;
    return this.httpAdapterService.get<TInvalidCriteriaList>(endpointUrl);
  }

  verifyChannelAvailabilityExists(logicGroups: Record<string, any>) {
    return logicGroups?.length === 0 ? false
      : logicGroups[0].children.some((nestedElement: Record<string, any>) => nestedElement.type === 'available_channel');
  }

  addDefaultCommunicationFilter(segmentJson: TCriteriaQuery) {
    const orFilterIndex = segmentJson.children.findIndex((item: TCriteriaChildren) => item.type === CriteriaTypeEnum.OR);
    if (orFilterIndex === -1) {
      segmentJson.children.push(this.segmentQueryService.getDefaultLkpCriteria());
    } else if ( orFilterIndex !== -1 &&  segmentJson.children[orFilterIndex]?.children ) {
      const channelAvailabilityExists = this.verifyChannelAvailabilityExists(segmentJson.children[orFilterIndex].children);
      if (!channelAvailabilityExists) {
        const child = segmentJson.children[orFilterIndex]?.children;
        if (!child.length) {
          segmentJson.children[orFilterIndex].children.push(this.segmentQueryService.getDefaultLkpCriteria().children[0]);
        } else {
          const isDefaultCriteriaPresent = child[0].children.some((criteria:any) => criteria.type === CriteriaIdEnum.CHANNEL_AVAILABILITY);
          if (!isDefaultCriteriaPresent) {
            child[0].children.push(this.segmentQueryService.getDefaultLkpCriteria().children[0].children[0]);
          }
        }
      }
    }
  }

  public getPopulationCount<T>(query: Record<string, any> | undefined, type: string, audience: IAudienceModel | null, audienceTypeFromToggle: AudienceTypeEnum | undefined): Observable<T> {
    const body = {
      segment: { criteria: query },
    };
    let params: Record<string, any> = {
      ...{ dns: audience?.includeDoNotSolicit || false },
      ...{ control_group: audience?.includeControlGroup || false },
      ...audience?.type && { audience_type: audienceTypeFromToggle },
      ...(type !== 'zip' && type !== 'overall' && audience?.channelPrecedence?.length) && { channel_precedence: audience?.channelPrecedence?.toString() },
    };
    return this.httpAdapterService.post<T>(`${this.apiBaseUrl}${this.audienceEndpoint.overallCount}/${type}`, body, { params });
  }

  public getCustomAttributeCriteria(): Observable<TCustomAttributeCriteriaModel> {
    return this.httpAdapterService.get(`${this.apiBaseUrl}${this.audienceEndpoint.customAttributeList}`);
  }

  public getCriteriaDescriptions(audienceCriteria: Record<string, any>): Observable<Record<string, any>> {
    return this.httpAdapterService.post(`${this.apiBaseUrl}${this.audienceEndpoint.criteriaDescription}`, audienceCriteria);
  }

  public transformCriteriaItems(criteriaItems: TCriteriaList, customAttributeCriterias: TCustomAttributeCriteriaItemModel[]): void {
    this.criteriaList = criteriaItems?.criterias?.map((listItem: TCriteria) => {
      listItem.type = listItem.id as string;
      this.setApplicableCriteria(listItem);
      if (listItem?.type === CriteriaIdEnum.EVENT_TYPE) {
        if (listItem.paths[0].conditions?.[0].value) listItem.paths[0].conditions[0].value = listItem.paths[0].conditions[0].value.filter((values: TCriteriaPathConditionValue) => this.activityTypes.includes(values.key as ActivityTypeEnum));
      } else if (listItem?.type === CriteriaIdEnum.EVENT_CHANNEL) {
        if (listItem.paths[0].conditions?.[0].value) listItem.paths[0].conditions[0].value = listItem.paths[0].conditions[0].value.filter((values: TCriteriaPathConditionValue) => this.associatedChannels.includes(values.key as string));
      } else if (listItem?.type === CriteriaIdEnum.NICHE_CODE) {
        if (listItem.paths[0].conditions?.[0].value) listItem.paths[0].conditions[0].value = listItem.paths[0].conditions[0].value.map((values: TCriteriaPathConditionValue, index: number) => ({
          key: values.key,
          key_as_string: values.key_as_string,
          pageNo: index + 3,
        }));
      }
      return listItem;
    });
    customAttributeCriterias?.forEach((value: TCustomAttributeCriteriaItemModel) => {
      const criteria = this.getTemplateBasedOnType(value);
      if (criteria) this.criteriaList.push(criteria);
    });
  }

  private getTemplateBasedOnType(customAttributeCriteria: TCustomAttributeCriteriaItemModel): TCriteria | null {
    const template: TCriteria = {
      name: customAttributeCriteria.display_name,
      parent: 'Custom Attributes',
      id: customAttributeCriteria.id,
      attribute_name: customAttributeCriteria.attribute_name,
      paths: [
        {
          name: customAttributeCriteria.display_name,
          conditions: [],
        },
      ],
      suppressableFor: [],
      validationPath: [],
    };
    switch (customAttributeCriteria?.attribute_type) {
      case 'boolean':
        template.type = 'person_custom_attribute_boolean';
        template.paths?.[0].conditions?.push({
          type: 'boolean',
          value: [
            {
              key: 0,
              key_as_string: 'false',
            },
            {
              key: 1,
              key_as_string: 'true',
            },
          ],
        });
        break;
      case 'number':
        template.type = 'person_custom_attribute_number';
        template.paths?.[0].conditions?.push({
          type: 'number',
          value: [
            {
              key: 'min',
              key_as_string: 'min',
            },
            {
              key: 'max',
              key_as_string: 'max',
            },
          ],
        });
        break;
      case 'date':
        template.type = 'person_custom_attribute_date';
        template.paths[0].name = 'Month';
        template.paths?.[0].conditions?.push(
          {
            type: 'range-single',
            label: '',
            min: 1,
            max: 36,
            interval: 1,
          },
          {
            type: 'select',
            label: 'Months From',
            value: [],
          },
        );
        break;
      case 'string':
        template.type = 'person_custom_attribute_string';
        template.paths?.[0]?.conditions?.push({
          type: 'typeahead-search',
        });
        break;
      default:
        return null;
    }
    return template;
  }

  private setApplicableCriteria(criteriaItem: TCriteria): void {
    if (!criteriaItem?.suppressableFor?.includes('LKP')) {
      criteriaItem?.validationPath?.forEach((path: TCriteriaValidationPath) => {
        this.LkpValidCriteriaList.push(criteriaItem.id as string);
        if (!this.LkpValidCriteriaList.includes(path.type)) {
          this.LkpValidCriteriaList.push(path.type);
        }
      });
    }
    if (!criteriaItem?.suppressableFor?.includes('INTERNAL')) {
      criteriaItem?.validationPath?.forEach((path: TCriteriaValidationPath) => {
        this.nonClientCriteriaList.push(criteriaItem.id as string);
        if (!this.nonClientCriteriaList.includes(path.type) && !this.internalTemplateExclusions.includes(path.type)) {
          this.nonClientCriteriaList.push(path.type);
        }
      });
    }
  }

  public getChartData(segment: TCriteriaQuery, audience: IAudienceModel, chartName: string): Observable<Highcharts.Options | any> {
    const payload = {
      chartType: SegmentMetricsChartTypeEnum.CHART_TYPE,
      type: chartName,
      segment: {
        criteria: {
          version: audience?.criteria?.version,
          query: segment,
        },
      },
    };

    const params: Record<string, any> = {
      ...{ dns: audience?.includeDoNotSolicit || false },
      ...{ control_group: audience?.includeControlGroup || false },
      ...audience?.type && { audienceType: audience?.type },
      ...(audience?.channelPrecedence?.length && chartName === SegmentMetricsChartTypeEnum.CHANNEL_AVAILABILITY) && { channel_precedence: audience?.channelPrecedence?.toString() },
    };
    return this.httpAdapterService.post<Highcharts.Options | any>(`${this.apiBaseUrl}${this.audienceEndpoint.segmentChart}`, payload, { params });
  }

  public getRiskModels(riskModelPayload: RiskModelsPayload): Observable<ICheckBox[]> {
    return this.httpAdapterService.post(`${this.apiBaseUrl}${this.audienceEndpoint.riskModels}`, riskModelPayload);
  }

  public getSegmentGeoLocations(segmentData: TAudienceCriteria, geoBoundingBox: TGeoBoundingBox, audience: IAudienceModel): Observable<{ buckets: GeoBoundary[] }> {
    const body = {
      geo_bounding_box: geoBoundingBox,
      segment: { criteria: segmentData },
    };
    const params: Record<string, any> = {
      ...{ dns: audience?.includeDoNotSolicit || false },
      ...{ control_group: audience?.includeControlGroup || false },
    };
    return this.httpAdapterService.post(`${this.apiBaseUrl}${this.audienceEndpoint.overallCount}/geoBoundary`, body, { params });

  }
  getFilterOptionsFromCriteriaList(filterDef: Record<string, any>) : any {
    const typeMapping: Record<string, string> = {
      facility_hierarchy: CriteriaIdEnum.FACILITY_CODE,
      move_to_market_area: CriteriaIdEnum.MOVE_TO_POSTAL_CODE,
      move_from_market_area: CriteriaIdEnum.MOVE_FROM_POSTAL_CODE,
      birth_month: CriteriaIdEnum.BIRTH_DATE,
    };

    const originalType = filterDef.type;
    filterDef.type = typeMapping[originalType] || originalType;

    const filterQuery: Record<string, any> = { 
      ...filterDef?.id &&  { id: filterDef.id }, 
      type: filterDef.type, 
    };
    if (filterDef.attribute_name) {
      filterQuery.attribute_name = filterDef.attribute_name;
    }
    filterDef.type = originalType === CriteriaIdEnum.FACILITY_CODE ? CriteriaIdEnum.FACILITY_HIERARCHY : originalType;
    return _.filter(this.criteriaList, filterQuery)[0];
  }

  getFilterType(filterDef: TCriteria) {
    if (filterDef.type === CriteriaIdEnum.FACILITY_CODE) {
      return CriteriaIdEnum.FACILITY_HIERARCHY;
    } else if ([CriteriaIdEnum.HG_CUST_SERVICE_CATEGORY, CriteriaIdEnum.HG_CUST_SERVICE_SUB_CATEGORY].includes(filterDef.type  as CriteriaIdEnum)) {
      return CriteriaFilterTypeEnum.CUSTOM_SERVICE_LINE;
    } else if ([CriteriaIdEnum.SERVICE_REFERRAL, CriteriaIdEnum.PHYSICIAN_REFERRAL, CriteriaIdEnum.EVENT_REGISTRATION, CriteriaIdEnum.PROVIDER, CriteriaIdEnum.EVENT_HRA, CriteriaIdEnum.CUSTOM_ACTIVITY].includes(filterDef.id as CriteriaIdEnum)) {
      return CriteriaFilterTypeEnum.ENUMERATED_TEMPLATE;
    } else if (filterDef.type === CriteriaIdEnum.MOVE_DISTANCE) {
      return CriteriaIdEnum.MOVE_DISTANCE;
    } else if (filterDef?.relation === 'tab') {
      if (filterDef.type === CriteriaIdEnum.CHILD_COUNT) {
        return CriteriaFilterTypeEnum.CHILDREN_COUNT;
      } else {
        return 'tab';
      }
    } else {
      const conditions = filterDef.paths[0].conditions;
      if (conditions?.length === 1) {
        return conditions[0].type === CriteriaFilterTypeEnum.TYPEAHEAD_SEARCH ? CriteriaFilterTypeEnum.TYPEAHEAD_SEARCH : CriteriaFilterTypeEnum.SINGLE_TEMPLATE;
      } else if (conditions && conditions.length > 1) {
        return CriteriaFilterTypeEnum.MULTIPLE_TEMPLATE;
      }
    }
    return '';
  }

  getTypeAheadSearchCustomAttribute(fieldType:string, key:string) :Observable<TCustomAttributeResponse> {
    const params: Record<string, string> = {
      ...{ searchField: fieldType },
      ...{ searchTerm: key },
    };
    return this.httpAdapterService.get<TCustomAttributeResponse>(`${this.apiBaseUrl}${this.audienceEndpoint.customAttributeSearch}`, { params });
  }

  getFilterOptionsFromCriteriaListBasedOnParent(param: Record<string, any>) {
    const { parent, type } = param;
    return this.criteriaList.filter((list:TCriteria) => {
      const isParentPresent = parent && (list?.parent === parent);
      const isTypePresent = type && list?.type && !type.includes(list.type);
      return isTypePresent && isParentPresent;
    });
  }

  public validateCriteria(segment: TCriteriaQuery, invalidCount: number): boolean {
    if (!segment || !segment.children) {
      return invalidCount > 0;
    }
    for (let i = segment.children.length - 1; i >= 0; i--) {
      const child: TCriteriaChildren = segment.children[i];
      const isSegmentType = [CriteriaTypeEnum.NOT, CriteriaTypeEnum.OR, CriteriaTypeEnum.AND, CriteriaTypeEnum.COMBINED].includes(child.type as CriteriaTypeEnum);
      if (!isSegmentType) {
        if (this.checkInvalidCriteria(child)) {
          invalidCount++;
          this.removeNAcriteria(segment, child);
        }
      }
      invalidCount = this.validateCriteria(child, invalidCount) ? invalidCount + 1 : invalidCount;
    }
    return invalidCount > 0;
  }

  private removeNAcriteria(segment: TCriteriaQuery, child: TCriteriaChildren) {
    if (!segment || !segment.children) {
      return;
    }
    const index = segment.children.indexOf(child);
    if (index > -1) {
      segment.children.splice(index, 1);
    }
  }

  private checkInvalidCriteria(criteria: TCriteriaChildren): boolean {
    return criteria.type ? !this.LkpValidCriteriaList.includes(criteria.type) : false;
  }

  public getRestrictedMetaOptions(campaignId: number) :Observable<string[]> {
    const endpointUrl = `${this.apiBaseUrl}${this.audienceEndpoint.restrictedManagedOptions}/${campaignId}/restrictedManagedOptions`;
    return this.httpAdapterService.get<string[]>(endpointUrl);
  }

  public checkAudienceCreatePermission(permission: string): void {
    this.ngxPermissionService.hasPermission(permission).then((status: boolean)=> {
      this.hasPermissionAccess = status;
    });
  }

  public openUnsavedAudienceModal(navLink?: string): void {
    this.unsavedAudienceModalRef = this.modalService.open(UnsavedAudienceConfirmModalComponent, { ariaLabelledBy: 'unsaved-audience-confirm-modal', backdrop: 'static', size: 'sm' });
    this.unsavedAudienceModalRef.result.then((result: { status: string; }) => {
      if (result.status === 'success' && navLink) {
        this.isCriteriaUnsaved = false;
        this.router.navigate([navLink]);
      }
    }).catch((reason: string | number) => console.log('<EditAudienceService> - <openUnsavedAudienceModal> Dismissed', reason));
  }
}

