import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxPermissionsModule } from 'ngx-permissions';

import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { CacheService } from './services/cache.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { HttpAdapterService } from './services/http-adapter.service';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthGuardCanActivate } from './guards/auth.guard';
import { PermissionGuardCanActivate } from './guards/permission.guard';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxPermissionsModule,
  ],
  providers: [
    AuthGuardCanActivate,
    PermissionGuardCanActivate,
    AuthService,
    ConfigService,
    ErrorHandlerService,
    CacheService,
    HttpAdapterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  exports: [],
})
export class CoreModule { }
