// Core Modules
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';

// External Modules
import { Observable, catchError, finalize, forkJoin, lastValueFrom, map, switchMap, take } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

// Services
import { AccessControlService } from '../permission/service/access-control.service';
import { AuthService } from '../services/auth.service';
import { SessionManagerService } from '../services/session-manager.service';

// Enums
import { MainNavigationEnum } from 'src/app/pem-shared/enum/main-navigation-url.enum';

/**
 * Permission Guard
 */
@Injectable()
export class PermissionGuardCanActivate {
  /**
   * The constructor method
   * @param accessControlService access control service
   * @param authService auth service
   * @param ngxPermissionsService permission service 
   * @param router router
   * @param sessionManager session manager service
   */
  constructor(
    private readonly accessControlService: AccessControlService,
    private readonly authService: AuthService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly router: Router,
    private readonly sessionManager: SessionManagerService,
  ) {}
  /**
   * method to validate activated route
   * @param next activate route
   * @returns permissions for route boolean: true/false
   */
  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> {
    const includePermissions = next.data?.permissions?.only || [] as Array<string>;
    const excludePermissions = next.data?.permissions?.except || [] as Array<string>;
    const redirectTo = next.data?.permissions?.redirectTo;
    return lastValueFrom(this.getPermissions(includePermissions, excludePermissions, redirectTo));
  }

  /**
   * Get the current user permission for app
   * @param routePermissions current route permission
   * @param redirectTo If fail redirect to this route
   * @returns
   */
  private getPermissions(includePermissions: string[], excludePermissions: string[], redirectTo: string): Observable<boolean> {
    this.authService.authenticationInProgressSubject.next(true);
    // If permission length is zero considering it as browser refresh and refreshing the users session, navigate to respective landing page
    if (Object.keys(this.ngxPermissionsService.getPermissions())?.length === 0 ) {
      return this.authService.refreshSession().pipe(
        take(1),
        switchMap(() => {
          this.sessionManager.setupSessionEvents();
          return this.accessControlService.definePermissions().pipe(
            switchMap(() => forkJoin([this.ngxPermissionsService.hasPermission(includePermissions), this.ngxPermissionsService.hasPermission(excludePermissions)])),
            map((hasAccess: boolean[]) => {
              const [hasIncludedPermissions, hasExcludedPermissions] = hasAccess;
              return this.getAccessStatus(includePermissions, excludePermissions, hasIncludedPermissions, hasExcludedPermissions, redirectTo);
            }),
          );
        }),
        catchError((error) => {
          console.log('<PermissionGuard> - <canActivate> - Failed while refreshing the session and navigating to specific page based on scope', error);
          this.sessionManager.stopWatching();
          this.authService.logout();
          return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]);
        }),
        finalize(() => this.authService.authenticationInProgressSubject.next(false)),
      );
    } else {
      return forkJoin([this.ngxPermissionsService.hasPermission(includePermissions), this.ngxPermissionsService.hasPermission(excludePermissions)]).pipe(
        map((hasAccess: boolean[]): boolean => {
          const [hasIncludedPermissions, hasExcludedPermissions] = hasAccess;
          return this.getAccessStatus(includePermissions, excludePermissions, hasIncludedPermissions, hasExcludedPermissions, redirectTo);
        }),
        finalize(() => this.authService.authenticationInProgressSubject.next(false)),
      );
    }
  }

  /**
   * getAccessStatus method is to navigate to redirectTo url if correct permission doesn't exist and return false or return true
   * @param includePermissions include permissions array for which permission was validated (permissions only)
   * @param excludePermissions exclude permissions array for which permission was validated (permissions except)
   * @param hasIncludedPermissionsAccess whether access is there for included permission
   * @param hasExcludedPermissionsAccess whether access is there for excluded permission
   * @param redirectTo URL to be navigated to if correct permission is not present
   * @returns boolean whether true or false
   */
  private getAccessStatus(includePermissions: string[], excludePermissions: string[], hasIncludedPermissionsAccess: boolean, hasExcludedPermissionsAccess: boolean, redirectTo: string): boolean {
    // If includePermissions is available and permission is not present or If excludePermissions is available and permission is present - navigate to redirectTo and return false
    if ((includePermissions.length && !hasIncludedPermissionsAccess) || (excludePermissions.length && hasExcludedPermissionsAccess)) {
      this.router.navigateByUrl(redirectTo);
      return false;
    } else return true;
  }
}

export const PermissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => inject(PermissionGuardCanActivate).canActivate(route);