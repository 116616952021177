import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import appContents from '../../../assets/app-contents.json';

import { AppEnvironmentConfig } from '../../pem-shared/models/environment-config.model';
import { IGainSightConfiguration } from '../../pem-shared/models/gain-insights-config.model';
import { IAuth0Configuration } from 'src/app/pem-shared/models/auth0-types.model';
import { IUserSettingsConfiguration } from '../../pem-shared/models/user-settings/user-settings-config.model';

/**
 * Config Service
 */
@Injectable()
export class ConfigService {

  /**
   * appEnvironment variable to hold the environment configurations
   */
  private appEnvironment: AppEnvironmentConfig;
  /**
   * Load all the app constants into a variables
   */
  private contents: any;

  /**
   * The constructor method
   */
  constructor() {
    this.appEnvironment = environment;
    this.contents = appContents;
  }

  /**
   * Utility method to get the endpoints by module name
   * @param module Module name to get endpoints list
   * @returns Module wise endpoint list
   */
  public getEndpointsByModule(module: string) {
    return this.contents.endpoints[module];
  }

  /**
   * Get the Refresh Session Interval config from environment. default 10 Mins
   * @returns duration
   */
  public get refreshSessionInterval(): number {
    return environment.SESSION.refreshSessionInterval;
  }

  /**
   * Get logoutNotifyExpiryTime by default 30 secs.
   * Notification waits this time for a user action. If no-action, we log the user off
   * @returns duration
   */
  public get logoutNotifyExpiryTime(): number {
    return environment.SESSION.logoutNotifyExpiryTime;
  }



  /**
   * @returns encryptKey
   * Used for encrypting, decrypting the User Profile, Meta Information
   */
  public get encryptKey(): string {
    return environment.LOCAL_STORAGE_ENCRYPTION;
  }

  /**
   * @returns string
   * Auth0 Tenant information
   */
  public get auth0Tenant(): string {
    return environment.AUTH0.tenant;
  }

  /**
   * @returns string
   * Auth0 Domain information
   */
  public get auth0Domain(): string {
    return environment.AUTH0.domain;
  }
  /**
   * Get the app content by section name,
   * section name should be parent field from the app-contents.json file
   * @sectionName section name to return content
   * @returns content data by section name, e.g. sectionName=footer OR header
   */
  public getContents(sectionName: string): any {
    return this.contents[sectionName];
  }

  /**
   * Get the feature state by feature name.
   * @param featureName feature name to get the state.
   * @returns feature state  : true/false.
   */
  public getFeatureState(featureName: string) {
    return this.appEnvironment.features[featureName];
  }

  /**
   * Get the Gainsight config from environment
   * @returns GainSight config : url, tagId.
   */
  public getGainSightConfiguration() : IGainSightConfiguration {
    return {
      url: this.appEnvironment.aptrinsic.url,
      tagId: this.appEnvironment.aptrinsic.tagId,
    };
  }

  /**
   * @returns API base URL
   */
  public get getAPIBaseUrl(): string {
    return environment.API;
  }

  /**
   * Utility method to get the user settings
   * @returns User settings endpoints
   */
  public get userSettingsEndpoints(): IUserSettingsConfiguration {
    return environment.USER_SETTINGS;
  }

  /**
   * method to get help center url
   * @returns Help Center URL
   */
  public get getHelpCenterUrl(): string {
    return environment.USER_SETTINGS.helpCenterUrl;
  }

  /**
   * method to get auth0 configurations
  * @returns Auth module configurations
  */
  public get getAuthConfig(): IAuth0Configuration {
    return environment.AUTH0;
  }

  /**
   * method to get section name
   * @param sectionName section name
   * @returns Section name
   */
  public getNavPathFromRelayState(sectionName: string): string {
    return `/${this.contents.relayMapper[sectionName]}`;
  }


  public dXeEnableDate(): Date {
    return new Date(environment.USER_SETTINGS.dXeEnabledDate);
  }
}
