import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { GenericErrorHandlerModel } from '../models/generic-error-handler.model';

/**
 * Generic Error Handler Component
 */
@Component({
  selector: 'app-generic-error-handler',
  templateUrl: './generic-error-handler.component.html',
  styleUrls: ['./generic-error-handler.component.scss'],
})
export class GenericErrorHandlerComponent implements OnInit {

  /**
   * contains data of type GenericErrorHandlerModel to show image , title and description
   */
  @Input() public errorhandlerData?: GenericErrorHandlerModel;

  /**
   * The constructor method
   * @param errorHandlerService error handler service
   */
  constructor(private readonly errorHandlerService: ErrorHandlerService) {
  }

  /**
   * The ngOnInit method
   */
  ngOnInit(): void {
    this.errorhandlerData = this.errorhandlerData?.description ? this.errorhandlerData : this.errorHandlerService.getErrorCodeData('default');
  }
}
