<div class="d-flex justify-content-center align-items-center h-100 min-height-vh-100"
  *ngIf="(auth.isAuthenticationInProgress$ | async)">
  <div class="spinner-border spinner-border-sm" role="status" id="authLoginProgressSpinner">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div *ngIf="(hideNavigation$ | async) === false || (auth.isAuthenticationInProgress$ | async)" id="app-header">
  <app-header [hideUserSettings]="hideNavigation$"></app-header>
</div>
<div id="app-content" *ngIf="(auth.isClientNavigationInProgress$ | async) === false"
  [ngClass]="{'h-100 mt-0': (hideNavigation$ | async)}">
  <div class="container-fluid mh-section-content"
    [ngClass]="{'h-100 w-100 px-0': (hideNavigation$ | async)}">
    <div class="row g-0" [ngClass]="{'h-100 w-100': (hideNavigation$ | async)}">
      <div class="col" [ngClass]="{'h-100': (hideNavigation$ | async)}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<app-back-to-top></app-back-to-top>
<div *ngIf="(hideNavigation$ | async) === false" id="app-footer" class="mt-auto">
  <app-footer></app-footer>
</div>