import { Component } from '@angular/core';
import { ITabModel } from 'src/app/pem-shared/models/tab/tab-config.model';
import tabConfigs from 'src/assets/tab-config.json';

/**
 * Style Guide Component
 */
@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss'],
})
export class StyleGuideComponent {

  /**
   * tabConfig to get tabs config
   */
  public tabConfig?: ITabModel;

  list : any[];
  selectedSkills: any [] = [];
  /**
   * The constructor method
   */
  constructor() {
    this.tabConfig = tabConfigs;

    this.list = 
    [
      { code:'IGP_21', description:'Behavioral Health', prefix:'IGP', selected:true, IGP_21:true, $$hashKey:'object:204' },
      { code:'IGP_01', description:'Cardiology', prefix:'IGP', selected:true, IGP_01:true, $$hashKey:'object:205' },
      { code:'IGP_05', description:'Digestive Health', prefix:'IGP', selected:true, IGP_05:true, $$hashKey:'object:206' },
      { code:'IGP_04', description:'ENT', prefix:'IGP', selected:true, 'IGP_04':true, $$hashKey:'object:207' },
      { code:'IGP_03', description:'Endocrinology', prefix:'IGP', selected:true, IGP_03:true, $$hashKey:'object:208' },
      { code:'IGP_06', description:'General Medicine', prefix:'IGP', selected:true, IGP_06:true, $$hashKey:'object:209' },
      { code:'IGP_07', description:'General Surgery', prefix:'IGP', selected:true, IGP_07:true, $$hashKey:'object:210' },
      { code:'IGP_10', description:'Gynecology', prefix:'IGP', selected:true, IGP_10:true, $$hashKey:'object:211' },
      { code:'IGP_08', description:'Neurosciences', prefix:'IGP', selected:true, IGP_08:true, $$hashKey:'object:212' },
      { code:'IGP_02', description:'Newborn Health', prefix:'IGP', selected:true, IGP_02:true, $$hashKey:'object:213' },
      { code:'IGP_09', description:'Obstetrics', prefix:'IGP', selected:true, IGP_09:true, $$hashKey:'object:214' },
      { code:'IGP_11', description:'Oncology & Hematology', prefix:'IGP', selected:true, IGP_11:true, $$hashKey:'object:215' },
      { code:'IGP_12', description:'Ophthalmology', prefix:'IGP', selected:true, IGP_12:true, $$hashKey:'object:216' },
      { code:'IGP_13', description:'Orthopedics', prefix:'IGP', selected:true, IGP_13:true, $$hashKey:'object:217' },
      { code:'IGP_15', description:'Other', prefix:'IGP', selected:true, IGP_15:true, $$hashKey:'object:218' },
      { code:'IGP_16', description:'Pulmonology', prefix:'IGP', selected:true, IGP_16:true, $$hashKey:'object:219' },
      { code:'IGP_20', description:'Rehabilitation', prefix:'IGP', selected:true, IGP_20:true, $$hashKey:'object:220' },
      { code:'IGP_14', description:'Spine', prefix:'IGP', selected:true, IGP_14:true, $$hashKey:'object:221' },
      { code:'IGP_18', description:'Transplant', prefix:'IGP', selected:true, IGP_18:true, $$hashKey:'object:222' },
      { code:'IGP_23', description:'Trauma', prefix:'IGP', selected:true, IGP_23:true, $$hashKey:'object:223' },
      { code:'IGP_19', description:'Urology', prefix:'IGP', selected:true, IGP_19:true, $$hashKey:'object:224' },
      { code:'IGP_22', description:'Vascular', prefix:'IGP', selected:false, IGP_22:false, $$hashKey:'object:225' },
    ];

    this.list.forEach(item=>{
      this.selectedSkills.push(item);
    });
  }

  callBack(name:any) {
    const index = this.selectedSkills.indexOf(name);
    this.selectedSkills.splice(index, 1); 
  }
}
