// Core Modules
import { Component, OnInit, OnDestroy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// External Modules
import { BehaviorSubject, filter, Subscription } from 'rxjs';

// Services
import { AuthService } from './core/services/auth.service';

// Enums
import { LocalStorageEnum } from './pem-shared/enum/local-storage-keys.enum';
import { MainNavigationEnum } from './pem-shared/enum/main-navigation-url.enum';
import { SubNavigationEnum } from './pem-shared/enum/sub-navigation-url.enum';

/**
 * App Component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, AfterContentChecked, OnDestroy {
  /**
   * router subscription
   */
  private routerSubscription = new Subscription();

  /**
   * Hide Navigation Subject
   */
  public hideNavigationSubject = new BehaviorSubject<boolean>(true);
  /**
   * hideNavigation$ observable for Hide Navigation emits
   */
  public hideNavigation$ = this.hideNavigationSubject.asObservable();

  /**
   * The constructor method
   * @param auth auth
   * @param sessionManager session manager
   * @param router router
   */
  constructor(
    public readonly auth: AuthService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  /**
   * The ngOnInit method
   */
  ngOnInit() {
    // Clearing out save_audience and next_state information from session storage at entry level.
    this.routerSubscription.add(
      this.router.events.pipe(filter(i => i instanceof NavigationEnd))
        .subscribe({
          next: (event: any) => {
            // If navigation involves login or client-list route set 'hideNavigationSubject' to true
            if ([`/${MainNavigationEnum.LOGIN}`, `/${MainNavigationEnum.CLIENT_LIST}`, '/'].includes(event.urlAfterRedirects)) this.hideNavigationSubject.next(true);
            // set 'hideNavigationSubject' to false when end event doesnot include login or client-list
            else this.hideNavigationSubject.next(false);
            // If routeUrl doesnot include 'campaign-workflow/campaign' clear the local storage tab values
            if (!event.urlAfterRedirects.includes(`/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}`)) {
              const clientId = this.auth.getClientId();
              // If clientId is present then remove the existing tab configurations present when user navigates out of campaigns route
              if (clientId) {
                localStorage.removeItem(`${LocalStorageEnum.CAMPAIGN_TABS}${clientId}`);
                localStorage.removeItem(`${LocalStorageEnum.CAMPAIGN_ACTIVE_TACTICS}${clientId}`);
                localStorage.removeItem(`${LocalStorageEnum.CAMPAIGN_ACTIVE_AUD}${clientId}`);
              }
            }
          },
          error: (e) => {
            console.log(`<AppComponent> - <ngOnInit> Error occurred during subscribing router events...`, e);
            this.hideNavigationSubject.next(false);
          },
          complete: () => this.hideNavigationSubject.complete(),
        }));
  }

  /**
   * Respond after Angular checks the content projected into the directive or component.
   */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * method to destroy routerSubscription
   */
  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.auth.authenticationInProgressSubject.unsubscribe();
    this.auth.clientNavigationInProgressSubject.unsubscribe();
  }

}
