import { Component, Input } from '@angular/core';
import { ConfigService } from '../../core/services/config.service';
import { HeaderContentModel } from '../models/header-content.model';
import { Observable } from 'rxjs';

/**
 * Header Component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  /**
   * headerContents to get header contents model
   */
  public headerContents: HeaderContentModel;
  /**
   * isMenuCollapsed for menu collapse: true/false
   */
  public isMenuCollapsed = false;

  /**
   * hideUserSettings is for hiding the User Settings
   */
  @Input() hideUserSettings?: Observable<boolean>;

  /**
   * The constructor method
   * @param configService config service
   */
  constructor(
    private configService: ConfigService,
  ) {
    // Load the header static contents from app-contents.json file
    this.headerContents = this.configService.getContents('header');
  }

}
