// Core Modules
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

// External Modules
import { NgbDropdownModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { NgxPermissionsModule } from 'ngx-permissions';

// External Services
import { CookieService } from 'ngx-cookie-service';

// Components
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { ClientListComponent } from './client-list/client-list.component';
import { CheckboxTreeComponent } from './checkbox-tree/checkbox-tree.component';
import { GenericErrorHandlerComponent } from './generic-error-handler/generic-error-handler.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ListViewComponent } from './list-view/list-view.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { MultiSearchComponent } from './multi-search/multi-search.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ScrollToSectionComponent } from './scroll-to-section/scroll-to-section.component';
import { SessionExpiryNotificationComponent } from './session-expiry-notification/session-expiry-notification.component';
import { SearchComponent } from './search/search.component';
import { ShowMoreLessComponent } from './show-more-less/show-more-less.component';
import { TableauPortalComponent } from './tableau-portal/tableau-portal.component';
import { TabsComponent } from './tabs/tabs.component';
import { TagsComponent } from './tags/tags.component';
import { ToastsContainerComponent } from './toasts/toasts-container.component';
import { UserSettingsComponent } from './header/user-settings/user-settings.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

// Services
import { UserSettingsService } from './services/user-settings/user-settings.service';
import { UtilsService } from './utils/utils.service';
import { InputValidatorDirective } from './directives/input-validator.directive';
import { ValidateMaxlengthDirective } from './directives/validate-maxlength.directive';
import { UrlValidator } from './directives/url/url.directive';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { ManageRestrictedPageComponent } from './manage-restricted-page/manage-restricted-page.component';
@NgModule({
  declarations: [
    AuthLoginComponent,
    BackToTopComponent,
    CheckboxTreeComponent,
    ClientListComponent,
    GenericErrorHandlerComponent,
    FooterComponent,
    HeaderComponent,
    ListViewComponent,
    MainNavigationComponent,
    MultiSearchComponent,
    PageTitleComponent,
    PaginationComponent,
    ScrollToSectionComponent,
    SearchComponent,
    SessionExpiryNotificationComponent,
    TableauPortalComponent,
    TabsComponent,
    TagsComponent,
    ToastsContainerComponent,
    ShowMoreLessComponent,
    UserSettingsComponent,
    UnauthorizedComponent,
    InputValidatorDirective,
    ValidateMaxlengthDirective,
    UrlValidator,
    SelectTemplateComponent,
    ManageRestrictedPageComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    NgbToastModule,
    NgPipesModule,
    NgxPermissionsModule,
    NgbDropdownModule,
    ScrollingModule,
  ],
  exports: [
    FormsModule,
    NgbDropdownModule,
    NgbModule,
    NgPipesModule,
    ReactiveFormsModule,
    AuthLoginComponent,
    BackToTopComponent,
    CheckboxTreeComponent,
    FooterComponent,
    HeaderComponent,
    ListViewComponent,
    MultiSearchComponent,
    PageTitleComponent,
    PaginationComponent,
    ScrollToSectionComponent,
    ShowMoreLessComponent,
    SearchComponent,
    TabsComponent,
    TagsComponent,
    ToastsContainerComponent,
    InputValidatorDirective,
    ValidateMaxlengthDirective,
    UrlValidator,
    SelectTemplateComponent,
    ManageRestrictedPageComponent,
  ],
  providers: [CookieService, DatePipe, UserSettingsService, UtilsService],
})
export class PemSharedModule { }
