// Core Modules
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

// External Modules
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';

// Models
import { ITabOptions, ITabModel } from '../models/tab/tab-config.model';

/**
 * Tab component
 */
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnChanges {

  /**
   * Input variable for tabConfig.
   */
  @Input() tabConfig?: ITabModel;
  /**
   * Emit button function
   */
  @Output() callBack: EventEmitter<Record<string, string | undefined>> = new EventEmitter<Record<string, string | undefined>>();
  /**
   * showTabComponent boolean value to show tab component
   */
  public showTabComponent: boolean = false;
  /**
   * active to show active tab
   */
  public active?: string;

  constructor(private ngxPermissionService: NgxPermissionsService) {}

  /**
   * A callback method that is invoked immediately after the default change detector has checked data-bound properties if at least one has changed, and before the view and content children are checked.
   * @param changes the input binding changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // when activeTab is present in changes update the current active tab
    if (!changes?.tabConfig?.firstChange && changes?.tabConfig?.currentValue?.activeTab) this.active = changes.tabConfig.currentValue.activeTab;
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time, and before any of the view or content children have been checked.
   */
  public ngOnInit(): void {
    if (this.tabConfig && this.tabConfig?.options?.length) {
      // setting up default tab based on current user scopes
      this.setDefaultTab();
      this.showTabComponent = true;
    }
  }

  setDefaultTab() {
    if (this.tabConfig && this.tabConfig.options) {
      if (this.tabConfig.activeTab) {
        const index = this.tabConfig?.options.findIndex((tab: ITabOptions)=> tab.id === this.tabConfig?.activeTab);
        if (index > -1) {
          const requiredScopeCheck = this.tabConfig.options[index].permissionScope as string | string[];
          this.ngxPermissionService.hasPermission(requiredScopeCheck).then((status: boolean) => {
            if (status) this.active = this.tabConfig?.activeTab;
            else this.setDefaultTabBasedOnPermission();
          });
        }
      } else {
        this.setDefaultTabBasedOnPermission();
      }
    }
  }

  setDefaultTabBasedOnPermission() {
    if (this.tabConfig && this.tabConfig.options) {
      this.tabConfig.options.forEach(async (option: ITabOptions)=> {
        if (!option?.permissionScope) {
          this.active = option.id;
          return;
        }
        const status = await this.ngxPermissionService.hasPermission(option.permissionScope);
        if (status) {
          this.active = option.id;
        }
      });
    }
  }

  /**
   * This function is used to remove tab
   * @param event
   * @param toRemove
   */
  removeTab(event: MouseEvent, tabOptionConfig?: ITabOptions): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.callBack.emit({ label: tabOptionConfig?.label, reRoute: tabOptionConfig?.reRoute, reRouteId: tabOptionConfig?.reRouteId });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    // Prevent navigation for option which has action modal
    const clickedOption = this.tabConfig?.options?.find((option) => option.id === changeEvent.nextId);
    if (clickedOption && clickedOption.action === 'modal-window-trigger') changeEvent.preventDefault();
    this.callBack.emit({ previousState: changeEvent.activeId, currentState: changeEvent.nextId });
  }

}