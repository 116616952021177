<div class="tags-panel" [attr.id]="idPrefix ? idPrefix+'-tags-panel' : 'tags-panel'">
  <div class="show-more-tag-panel">
    <cdk-virtual-scroll-viewport [itemSize]="10" [ngClass]="{'disableScroll': mapController}" class="tags p-2" [ngClass]="configOptions.height ? 'audience-height' : 'campaign-height'" [attr.id]="idPrefix ? idPrefix+'-tags' : 'tags'">
      <div *cdkVirtualFor="let tag of itemConfig | orderBy: '[configOptions.value]'"
        class="tag m-1 d-inline-flex font-size-14 text-center align-items-center" [ngClass]="{'moreWidth': mapController}"
        [ngbTooltip]="tipContent" placement="top" container="body">
        <span class="d-inline-block" [ngClass]="{'disableTruncate': mapController}">
          <span class="fw-bold" *ngIf="tag.prefix">{{tag.prefix}}:</span> {{!configOptions.showKey ? tag[configOptions.value] : tag[configOptions.key]}}
        </span>
        <a href="javascript:void(0)" aria-label='Close Button' (click)="deselectAttribute(tag)">
          <i class="mh-material-icons close-select">close <span class="visually-hidden"></span></i>
        </a>
        <ng-template #tipContent>
          <span *ngIf="tag[configOptions.value] && (tag[configOptions.key] === tag[configOptions.value])">{{tag[configOptions.key]}}</span>
          <span *ngIf="!(tag[configOptions.value] && (tag[configOptions.key] === tag[configOptions.value]))">
            <span class="fw-bold"
              *ngIf="tag.prefix && tag.prefix.length>0 && tag[configOptions.key] && tag[configOptions.key].length>0">{{(tag[configOptions.key]).substring(tag.prefix.length+1)}}:
            </span>
            <span class="fw-bold" *ngIf="!tag.prefix">{{tag[configOptions.key]}}:</span> {{tag[configOptions.value]}}
          </span>
        </ng-template>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>