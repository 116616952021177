// Core Modules
import { Component, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListViewComponent {

  public selectedFilter?: string;

  @Input() displayLoader: boolean = false;
  @Input() settings?: any;
  @Input() items?: Record<string, any>[];
  @Input() headers: Record<string, any> = {};
  @Input() template!: TemplateRef<any>;
  @Input() headerTemplate!: TemplateRef<any>;
  @Input() popoverContent!: TemplateRef<any>;
  @Input() previewPopoverContent!: TemplateRef<any>;
  @Input() buttonContent!: TemplateRef<any>;
  @Input() iconContent!: TemplateRef<any>;
  @Input() defaultSort!: string;

  @Output() listViewChange:EventEmitter<Record<string, string>> = new EventEmitter<Record<string, string>>();

  callback(arg: Record<string, any>) {
    let param: Record<string, any> = {};
    if (arg.type === 'sorting') {
      this.defaultSort = arg.element.value.name;
      arg.element.value.defaultSort = !arg.element.value.defaultSort;
      arg.element.value.active = !arg.element.value.active;
      param.type = 'sorting';
      param.key = arg.element.key;
      param.sortBy = arg.element.value.active ? 'asc' : 'desc';
    } else if (arg.type === 'link') {
      param.type = 'link';
      param.row = arg.row;
      param.column = arg.column;
    } else {
      if (arg.type === 'filter') this.selectedFilter = arg.value;
      param = arg;
    }
    this.listViewChange.emit(param);
  }
}
